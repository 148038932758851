import React, { Component } from 'react';

export default class NotFoundSvg extends Component {
    render() {
        return (
            <>
                {this.props.svg ?
                    <svg width="350" height="352" viewBox="0 0 397 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M47.283 259.627L33.0422 259.896L25.8354 260.011C23.4267 260.011 21.0372 260.011 18.5902 260.011H18.168V259.579V247.459V247.018H18.6478L32.9942 247.085C37.7923 247.085 42.5904 247.19 47.3885 247.238H47.6476V247.497C47.6476 249.551 47.5613 251.595 47.5229 253.62L47.283 259.627ZM47.283 259.627L47.139 253.514C47.139 251.499 47.0335 249.493 47.0143 247.507L47.283 247.766C42.4849 247.824 37.6867 247.92 32.8886 247.929L18.5902 247.977L19.07 247.497L19.022 259.617L18.5902 259.195C20.9605 259.195 23.3212 259.195 25.6914 259.243L32.8214 259.358L47.283 259.627Z" fill="#E6E8EC" />
                        <path d="M31.3113 274.913L17.08 275.182L9.86365 275.297C7.455 275.297 5.06553 275.297 2.61848 275.297H2.19625V274.865L2.13867 262.745V262.266H2.61848L16.9169 262.371C21.715 262.371 26.5131 262.486 31.2633 262.534H31.5224V262.793C31.5224 264.847 31.4456 266.891 31.3976 268.916L31.3113 274.913ZM31.3113 274.913L31.1769 268.801C31.1769 266.785 31.0714 264.78 31.0522 262.793L31.3113 263.052C26.5131 263.11 21.715 263.206 16.9649 263.216L2.61848 263.273L3.0983 262.793L3.05032 274.913L2.61848 274.491C4.98876 274.491 7.34944 274.491 9.71971 274.539L16.8593 274.654L31.3113 274.913Z" fill="#E6E8EC" />
                        <path d="M31.3125 12.5903L17.0429 12.8686L9.82654 12.9741C7.41788 12.9741 5.02842 12.9741 2.58137 13.0317H2.15914V12.5999L2.10156 0.479812V0H2.58137L16.9182 0.0479805C21.7163 0.0479805 26.5144 0.163137 31.2646 0.211119H31.5237V0.470218C31.5237 2.52382 31.4469 4.56781 31.3989 6.59262L31.3125 12.5903ZM31.3125 12.5903L31.1782 6.47747C31.1782 4.46226 31.0726 2.45664 31.0534 0.470218L31.3125 0.729313C26.5144 0.729313 21.7163 0.882855 16.9661 0.892451L2.61976 0.95003L3.09957 0.470218L3.05159 12.5903L2.61976 12.168C4.99003 12.168 7.35071 12.168 9.72098 12.168L16.8606 12.2832L31.3125 12.5903Z" fill="#E6E8EC" />
                        <path d="M393.992 12.5903L379.751 12.8686L372.544 12.9741C370.136 12.9741 367.746 12.9741 365.299 13.0317H364.877V12.5999V0.479812V0H365.357L379.703 0.0575788C384.501 0.0575788 389.299 0.172732 394.05 0.220713H394.309V0.479812C394.309 2.53341 394.222 4.57741 394.184 6.60222L393.992 12.5903ZM393.992 12.5903L393.848 6.47747C393.848 4.46226 393.742 2.45664 393.723 0.470218L393.992 0.729313C389.194 0.729313 384.396 0.882855 379.646 0.892451L365.261 0.95003L365.741 0.470218L365.693 12.5903L365.261 12.168C367.631 12.168 369.992 12.168 372.362 12.168L379.492 12.2832L393.992 12.5903Z" fill="#E6E8EC" />
                        <path d="M377.987 27.879L363.756 28.1477L356.539 28.2533C354.131 28.2533 351.741 28.2533 349.294 28.3108H348.872V27.879L348.814 15.7493V15.2695H349.294L363.641 15.3271C368.439 15.3271 373.237 15.4423 377.987 15.4902H378.256V15.7493C378.256 17.8029 378.179 19.8469 378.131 21.8717L377.987 27.879ZM377.987 27.879L377.853 21.7662C377.805 19.7414 377.747 17.7454 377.728 15.7589L377.987 16.018C373.189 16.018 368.391 16.1716 363.641 16.1812L349.294 16.2388L349.774 15.7589L349.726 27.879L349.294 27.4568C351.665 27.4568 354.025 27.4568 356.396 27.4568L363.535 27.5719L377.987 27.879Z" fill="#E6E8EC" />
                        <path d="M304.509 169.71L290.277 169.979L283.061 170.084C280.652 170.084 278.263 170.084 275.826 170.084H275.394V169.652L275.346 157.523V157.043H275.835L290.172 157.11C294.97 157.11 299.768 157.216 304.518 157.264H304.806V157.523C304.806 159.576 304.729 161.62 304.681 163.645L304.509 169.71ZM304.509 169.71L304.374 163.588C304.326 161.572 304.269 159.576 304.25 157.58L304.509 157.849C299.711 157.849 294.912 158.003 290.162 158.003L275.826 158.06L276.296 157.58L276.248 169.71L275.826 169.278C278.186 169.278 280.547 169.278 282.917 169.336L290.057 169.441L304.509 169.71Z" fill="#E6E8EC" />
                        <path d="M288.493 184.996L274.262 185.265L267.055 185.37C264.646 185.418 262.257 185.37 259.81 185.418H259.378V184.986L259.33 172.857V172.377H259.81L274.156 172.444C278.954 172.444 283.753 172.55 288.493 172.598H288.762V172.857C288.762 174.91 288.675 176.954 288.637 178.979L288.493 184.996ZM288.493 184.996L288.359 178.874C288.359 176.858 288.253 174.862 288.234 172.866L288.493 173.135C283.695 173.183 278.897 173.289 274.156 173.289L259.81 173.346L260.29 172.866L260.232 184.996L259.81 184.564C262.171 184.564 264.541 184.564 266.911 184.622L274.041 184.727L288.493 184.996Z" fill="#E6E8EC" />
                        <path d="M272.563 169.711L258.332 169.98L251.125 170.085C248.707 170.085 246.327 170.085 243.88 170.085H243.448V169.653L243.391 157.581V157.102H243.88L258.217 157.169C263.015 157.169 267.813 157.274 272.563 157.322H272.832V157.581C272.832 159.635 272.746 161.679 272.698 163.704L272.563 169.711ZM272.563 169.711L272.429 163.589C272.429 161.573 272.323 159.577 272.304 157.581L272.563 157.85C267.765 157.85 262.967 158.004 258.217 158.004L243.88 158.061L244.36 157.581L244.35 169.711L243.928 169.279C246.289 169.279 248.649 169.279 251.029 169.337L258.159 169.442L272.563 169.711Z" fill="#E6E8EC" />
                        <path d="M393.992 274.913L379.751 275.182L372.544 275.297C370.136 275.297 367.746 275.297 365.299 275.297H364.877V274.865V262.745V262.266H365.357L379.655 262.371C384.453 262.371 389.251 262.486 394.002 262.534H394.261V262.793C394.261 264.847 394.174 266.891 394.136 268.916L393.992 274.913ZM393.992 274.913L393.848 268.801C393.848 266.785 393.742 264.78 393.723 262.793L393.992 263.052C389.194 263.11 384.396 263.206 379.646 263.216L365.299 263.273L365.779 262.793L365.731 274.913L365.299 274.491C367.669 274.491 370.03 274.491 372.4 274.539L379.53 274.654L393.992 274.913Z" fill="#E6E8EC" />
                        <path d="M150.239 48.6428L135.998 48.9115L128.792 49.0171C126.383 49.0171 123.993 49.0171 121.546 49.0747H121.124V48.6428L121.076 36.5228V36.043H121.556L135.902 36.1005C140.701 36.1005 145.499 36.2061 150.297 36.2637H150.556V36.5228C150.556 38.5764 150.469 40.6204 150.431 42.6356L150.239 48.6428ZM150.239 48.6428L150.095 42.53C150.095 40.5052 149.99 38.5092 149.97 36.5228L150.239 36.7819C145.441 36.7819 140.643 36.9354 135.845 36.9354L121.518 37.0026L121.997 36.5228L121.949 48.6428L121.518 48.2206C123.888 48.2206 126.249 48.2206 128.619 48.2206L135.749 48.3262L150.239 48.6428Z" fill="#E6E8EC" />
                        <path d="M0 341.376L49.5742 341.136L99.1484 341.06L198.287 340.896L297.426 341.05L347 341.136L396.575 341.376L347 341.607L297.426 341.693L198.287 341.847L99.1484 341.693L49.5742 341.607L0 341.376Z" fill="#141416" />
                        <path d="M130.08 399.999C191.081 399.999 240.533 392.179 240.533 382.534C240.533 372.888 191.081 365.068 130.08 365.068C69.0784 365.068 19.627 372.888 19.627 382.534C19.627 392.179 69.0784 399.999 130.08 399.999Z" fill="#F4F5F6" />
                        <path d="M364.823 341.377H218.883C218.883 321.628 211.436 221.338 291.815 221.338C372.193 221.338 364.823 321.628 364.823 341.377Z" fill="#F4F5F6" />
                        <path d="M319.422 226.213C317.023 226.002 314.519 225.887 311.928 225.887C234.572 225.887 241.731 322.377 241.731 341.378H218.873C218.873 321.628 211.436 221.338 291.804 221.338C301.237 221.183 310.612 222.838 319.422 226.213Z" fill="#E6E8EC" />
                        <path d="M77.3763 183.568V151.248H14.041V125.444L86.7038 32.1777H106.434V125.261H125.521V151.248H106.434V183.568H77.3763ZM43.1081 125.223H80.6294V76.2053L43.1081 125.223Z" fill="#141416" />
                        <path d="M334.382 183.568V151.248H271.047V125.444L343.7 32.1777H363.44V125.261H382.536V151.248H363.449V183.568H334.382ZM300.114 125.223H337.635V76.2053L300.114 125.223Z" fill="#141416" />
                        <path d="M163.118 42.0149C172.072 37.1209 180.065 38.829 185.497 38.7522C190.928 38.6755 201.954 31.1616 208.288 32.2939C214.621 33.4263 245.675 48.3773 250.588 55.0946C255.501 61.812 263.677 78.8262 264.445 82.2616C265.213 85.6971 263.197 105.101 264.138 109.544C265.078 113.987 262.027 121.222 260.127 124.332C258.227 127.441 253.649 139.081 252.517 141.202C251.384 143.323 222.279 166.325 212.088 167.774C201.897 169.223 197.933 166.334 196.82 165.701C195.707 165.068 199.066 157.822 193.778 156.105C188.491 154.387 186.514 153.523 186.514 153.523C186.514 153.523 185.017 149.08 182.234 148.034C179.451 146.988 175.517 149.954 175.517 149.954C175.517 149.954 174.327 150.616 172.014 149.55C169.701 148.485 166.458 149.915 166.458 149.915C165.67 148.478 164.378 147.384 162.83 146.844C160.134 145.885 156.977 151.585 156.977 151.585C156.977 151.585 139.348 136.845 138.379 133.227C137.41 129.609 137.419 124.802 136.066 120.32C134.713 115.839 131.959 113.737 131.911 106.214C131.863 98.6904 133.466 82.799 135.443 79.0277C137.419 75.2564 139.147 69.9592 140.385 68.2991C141.623 66.6389 153.858 47.0722 163.118 42.0149Z" fill="#3772FF" />
                        <g opacity="0.3">
                            <path opacity="0.3" d="M163.118 42.013C172.072 37.1189 180.065 38.827 185.497 38.7503C190.928 38.6735 201.954 31.1596 208.288 32.292C214.621 33.4244 245.675 48.3753 250.588 55.0927C255.501 61.8101 263.677 78.8242 264.445 82.2597C265.213 85.6951 263.197 105.099 264.138 109.542C265.078 113.985 262.027 121.22 260.127 124.33C258.227 127.439 253.649 139.079 252.517 141.2C251.384 143.321 222.279 166.323 212.088 167.772C201.897 169.221 197.933 166.332 196.82 165.699C195.707 165.066 199.066 157.821 193.778 156.103C188.491 154.385 186.514 153.521 186.514 153.521C186.514 153.521 185.017 149.078 182.234 148.032C179.451 146.986 175.517 149.952 175.517 149.952C175.517 149.952 174.327 150.614 172.014 149.549C169.701 148.483 166.458 149.913 166.458 149.913C165.67 148.476 164.378 147.382 162.83 146.842C160.134 145.883 156.977 151.583 156.977 151.583C156.977 151.583 139.348 136.843 138.379 133.225C137.41 129.608 137.419 124.8 136.066 120.318C134.713 115.837 131.959 113.735 131.911 106.212C131.863 98.6884 133.466 82.7971 135.443 79.0257C137.419 75.2544 139.147 69.9573 140.385 68.2971C141.623 66.637 153.858 47.0702 163.118 42.013Z" fill="white" />
                        </g>
                        <path d="M223.24 139.186C224.717 137.382 228.489 137.084 228.93 139.022C229.372 140.961 228.364 143.197 225.802 143.139C223.24 143.082 222.664 139.915 223.24 139.186Z" fill="#3772FF" />
                        <path opacity="0.1" d="M225.515 143.111C223.221 142.823 222.694 139.887 223.269 139.186C223.719 138.702 224.266 138.319 224.875 138.062C225.484 137.806 226.14 137.682 226.801 137.699C226.139 137.939 224.546 138.754 224.306 139.743C224.066 140.731 223.989 142.42 225.515 143.111Z" fill="black" />
                        <path d="M142.63 100.311C145.164 95.3499 153.484 97.9697 154.223 102.442C154.962 106.913 150.509 110.119 147.313 109.888C144.118 109.658 139.876 105.743 142.63 100.311Z" fill="#3772FF" />
                        <path d="M234.862 106.588C230.88 105.341 220.871 107.903 218.836 116.511C217.675 121.424 223.98 131.673 230.438 132.229C236.896 132.786 241.675 126.078 243.115 120.58C244.554 115.081 240.965 108.498 234.862 106.588Z" fill="#3772FF" />
                        <path opacity="0.1" d="M231.983 132.219C231.47 132.272 230.952 132.272 230.438 132.219C223.98 131.662 217.675 121.413 218.836 116.5C220.861 107.864 230.88 105.321 234.862 106.578C235.603 106.824 236.317 107.145 236.992 107.537C231.83 108.497 224.738 113.669 224.153 117.354C223.529 121.308 226.273 131.317 231.983 132.219Z" fill="black" />
                        <path d="M204.045 41.7755C203.719 45.0671 200.206 47.303 197.327 49.5389C194.449 51.7748 188.691 50.8152 184.67 48.9056C180.649 46.9959 178.288 39.1174 178.394 38.532C181.043 38.532 183.422 38.7815 185.447 38.7527C189.18 38.7527 195.581 35.1157 201.271 33.2637C202.452 35.4804 204.294 39.4724 204.045 41.7755Z" fill="#3772FF" />
                        <path opacity="0.1" d="M195.123 50.5669C192.071 51.3154 187.868 50.3942 184.711 48.9068C180.671 46.9875 178.33 39.1186 178.435 38.5332C180.546 38.5332 182.485 38.6963 184.193 38.7443C184.692 40.7979 186.045 45.8264 187.551 47.1698C189.5 48.878 193.098 50.4901 195.123 50.5669Z" fill="black" />
                        <path d="M236.508 69.7486C239.186 67.8293 244.06 69.7486 244.387 73.5103C244.713 77.272 239.809 79.4312 236.508 76.8786C235.943 76.4713 235.483 75.9358 235.166 75.316C234.849 74.6962 234.684 74.0098 234.684 73.3136C234.684 72.6173 234.849 71.931 235.166 71.3112C235.483 70.6914 235.943 70.1558 236.508 69.7486Z" fill="#3772FF" />
                        <path opacity="0.1" d="M237.362 72.3011C236.806 74.3739 237.055 75.8325 239.003 77.9341C238.093 77.8075 237.233 77.4434 236.508 76.8785C235.943 76.4713 235.483 75.9357 235.166 75.3159C234.849 74.6961 234.684 74.0097 234.684 73.3135C234.684 72.6172 234.849 71.9309 235.166 71.3111C235.483 70.6913 235.943 70.1557 236.508 69.7485C237.221 69.303 238.036 69.0464 238.875 69.0028C239.714 68.9591 240.551 69.1299 241.306 69.499C241.114 69.4126 237.919 70.2283 237.362 72.3011Z" fill="black" />
                        <path d="M195.956 157.485C195.36 156.841 194.61 156.359 193.777 156.084C188.48 154.347 186.513 153.503 186.513 153.503C186.513 153.503 185.016 149.06 182.233 148.014C179.45 146.968 175.516 149.933 175.516 149.933C176.159 144.473 178.49 142.256 182.425 140.183C186.359 138.111 193.278 139.051 195.409 144.377C196.925 148.1 196.368 154.127 195.956 157.485Z" fill="#3772FF" />
                        <path opacity="0.1" d="M190.047 139.665C188.44 139.744 186.868 140.164 185.435 140.897C184.002 141.63 182.742 142.659 181.737 143.916C181.056 145.119 180.628 146.448 180.48 147.822C178.695 148.145 177.011 148.884 175.566 149.981C176.209 144.521 178.541 142.304 182.476 140.231C184.838 139.094 187.542 138.892 190.047 139.665Z" fill="black" />
                        <path d="M167.83 65.8235C160.873 69.662 156.161 80.3426 156.42 83.9029C156.679 87.4631 159.05 93.1632 162.121 95.879C169.03 101.992 182.656 99.1897 185.967 95.879C189.278 92.5683 193.001 82.3962 191.821 77.4062C189.854 69.0671 174.941 61.9178 167.83 65.8235Z" fill="#3772FF" />
                        <path opacity="0.1" d="M177.437 99.1807C172.264 99.958 166.084 99.3918 162.083 95.8796C159.012 93.1638 156.632 87.4733 156.383 83.913C156.133 80.3528 160.835 69.6434 167.792 65.8241C169.98 64.6246 172.898 64.4711 175.949 65.0756C172.36 67.7146 166.439 72.5895 165.144 76.8598C163.225 83.0878 164.367 89.5844 167.063 93.3557C168.848 95.7644 173.972 97.9332 177.437 99.1807Z" fill="black" />
                        <path d="M152.707 110.627C151.395 111.515 149.838 111.972 148.255 111.933C144.522 111.683 139.57 107.134 142.785 100.763C143.372 99.6609 144.289 98.7701 145.407 98.2145C146.526 97.6589 147.789 97.466 149.022 97.6629C152.391 97.9604 155.807 100.244 156.315 103.248C156.519 104.698 156.285 106.175 155.642 107.49C154.999 108.806 153.977 109.898 152.707 110.627Z" fill="#3772FF" />
                        <path opacity="0.1" d="M152.707 110.627C151.395 111.515 149.838 111.972 148.255 111.933C144.522 111.683 139.57 107.134 142.785 100.763C143.372 99.6609 144.289 98.7701 145.407 98.2145C146.526 97.6589 147.789 97.466 149.022 97.6629C147.573 98.7665 145.289 101.012 145.289 104.16C145.289 108.775 148.168 109.917 149.943 110.541C150.845 110.788 151.792 110.818 152.707 110.627Z" fill="black" />
                        <path d="M230.541 81.7829C230.205 82.0193 229.797 82.1298 229.387 82.095C228.978 82.0601 228.594 81.882 228.303 81.5922C228.012 81.3023 227.832 80.9191 227.796 80.5099C227.759 80.1008 227.868 79.6919 228.103 79.355C228.249 79.0841 228.474 78.8648 228.749 78.7273C229.024 78.5899 229.335 78.541 229.639 78.5873C230.044 78.5996 230.435 78.7399 230.756 78.9881C231.077 79.2364 231.31 79.5798 231.424 79.9692C231.475 80.3252 231.419 80.6884 231.261 81.0118C231.104 81.3352 230.853 81.6039 230.541 81.7829Z" fill="#3772FF" />
                        <path opacity="0.1" d="M230.541 81.7829C230.205 82.0193 229.797 82.1298 229.387 82.095C228.978 82.0601 228.594 81.882 228.303 81.5922C228.012 81.3023 227.832 80.9191 227.796 80.5099C227.759 80.1008 227.868 79.6919 228.103 79.355C228.249 79.0841 228.474 78.8648 228.749 78.7273C229.024 78.5899 229.335 78.541 229.639 78.5873C229.367 78.7635 229.139 78.9995 228.973 79.2773C228.807 79.5551 228.706 79.8673 228.679 80.1899C228.649 80.5456 228.748 80.9003 228.957 81.1893C229.167 81.4782 229.474 81.6821 229.821 81.7637C230.056 81.8297 230.303 81.8363 230.541 81.7829Z" fill="black" />
                        <path d="M153.034 119.619C155.414 118.506 159.406 120.022 158.907 122.21C158.408 124.398 156.316 126.192 153.725 124.897C151.134 123.601 152.065 120.079 153.034 119.619Z" fill="#3772FF" />
                        <path opacity="0.1" d="M157.87 124.189C157.363 124.761 156.673 125.141 155.917 125.263C155.162 125.386 154.387 125.243 153.725 124.861C151.143 123.565 152.055 120.062 153.034 119.583C153.761 119.265 154.558 119.142 155.347 119.228C154.679 119.861 154.249 120.705 154.128 121.617C154.147 123.104 156.066 124.601 157.87 124.189Z" fill="black" />
                        <path d="M166.113 190.132V194.239H151.719V173.799H165.835V177.944H156.44V181.917H164.511V185.756H156.44V190.103L166.113 190.132Z" fill="#141416" />
                        <path d="M169.002 194.239V173.799H178.214C179.135 173.791 180.046 173.998 180.873 174.404C181.673 174.794 182.392 175.331 182.993 175.987C183.582 176.64 184.053 177.39 184.385 178.204C184.716 178.998 184.889 179.848 184.893 180.708C184.897 181.327 184.816 181.944 184.653 182.541C184.506 183.117 184.287 183.671 184.001 184.192C183.714 184.71 183.355 185.185 182.936 185.602C182.523 186.015 182.055 186.367 181.544 186.648L186.035 194.249H180.709L176.794 187.656H173.723V194.239H169.002ZM173.723 183.53H178.042C178.327 183.525 178.608 183.451 178.858 183.314C179.109 183.176 179.323 182.981 179.481 182.743C179.904 182.151 180.116 181.435 180.086 180.708C180.121 179.973 179.874 179.252 179.395 178.693C179.212 178.47 178.984 178.288 178.726 178.159C178.468 178.03 178.186 177.957 177.898 177.945H173.723V183.53Z" fill="#141416" />
                        <path d="M188.291 194.239V173.799H197.494C198.418 173.791 199.331 173.998 200.162 174.404C200.962 174.794 201.681 175.331 202.282 175.987C202.871 176.64 203.342 177.39 203.674 178.204C204.002 178.998 204.172 179.849 204.173 180.708C204.173 181.326 204.099 181.941 203.952 182.541C203.793 183.114 203.571 183.668 203.29 184.192C203.005 184.706 202.65 185.177 202.234 185.593C201.822 186.005 201.353 186.357 200.843 186.639L205.324 194.239H200.008L196.093 187.646H193.012V194.239H188.291ZM193.012 183.53H197.331C197.616 183.525 197.897 183.451 198.147 183.314C198.398 183.176 198.612 182.981 198.77 182.743C199.185 182.148 199.394 181.433 199.365 180.708C199.404 179.974 199.16 179.253 198.684 178.693C198.5 178.469 198.271 178.286 198.011 178.157C197.751 178.028 197.467 177.955 197.177 177.945H193.012V183.53Z" fill="#141416" />
                        <path d="M216.522 194.452C215.091 194.47 213.674 194.165 212.377 193.559C211.169 192.995 210.083 192.199 209.181 191.218C208.298 190.243 207.602 189.114 207.128 187.888C206.643 186.666 206.392 185.364 206.389 184.049C206.388 182.716 206.649 181.396 207.156 180.163C208.157 177.683 210.08 175.688 212.521 174.597C213.806 174.021 215.2 173.73 216.609 173.743C218.043 173.726 219.463 174.031 220.764 174.635C221.972 175.208 223.057 176.01 223.959 176.996C224.838 177.976 225.531 179.107 226.003 180.335C226.473 181.561 226.714 182.862 226.714 184.174C226.717 185.507 226.46 186.827 225.955 188.06C225.461 189.284 224.742 190.403 223.835 191.362C222.916 192.322 221.821 193.097 220.61 193.645C219.323 194.21 217.928 194.486 216.522 194.452ZM211.206 184.088C211.206 184.864 211.319 185.637 211.542 186.381C211.761 187.094 212.109 187.761 212.569 188.348C213.037 188.959 213.642 189.451 214.336 189.784C215.029 190.118 215.791 190.283 216.561 190.268C217.381 190.285 218.192 190.097 218.921 189.721C219.57 189.371 220.136 188.887 220.582 188.3C221.017 187.7 221.342 187.028 221.541 186.314C221.763 185.579 221.876 184.816 221.877 184.049C221.88 183.274 221.76 182.503 221.522 181.765C221.302 181.065 220.95 180.414 220.486 179.846C220.028 179.281 219.456 178.82 218.806 178.493C218.101 178.125 217.318 177.931 216.522 177.927C215.706 177.907 214.898 178.091 214.171 178.464C213.523 178.806 212.954 179.28 212.501 179.856C212.044 180.445 211.702 181.116 211.494 181.832C211.289 182.566 211.192 183.326 211.206 184.088Z" fill="#141416" />
                        <path d="M229.449 194.239V173.799H238.662C239.586 173.791 240.499 173.998 241.329 174.404C242.126 174.794 242.843 175.331 243.441 175.987C244.035 176.637 244.51 177.387 244.842 178.204C245.17 178.998 245.339 179.849 245.341 180.708C245.343 181.327 245.265 181.943 245.11 182.541C244.96 183.117 244.737 183.672 244.448 184.192C243.887 185.227 243.036 186.077 242.001 186.639L246.492 194.239H241.166L237.251 187.646H234.171V194.239H229.449ZM234.171 183.53H238.489C238.775 183.525 239.055 183.451 239.306 183.314C239.556 183.176 239.77 182.981 239.928 182.743C240.351 182.151 240.564 181.435 240.533 180.708C240.569 179.973 240.321 179.252 239.842 178.693C239.66 178.469 239.433 178.286 239.174 178.157C238.916 178.028 238.633 177.956 238.345 177.945H234.171V183.53Z" fill="#141416" />
                        <path d="M24.952 27.4955C23.5206 27.5138 22.1035 27.2087 20.8064 26.603C19.598 26.0354 18.5124 25.2367 17.6109 24.252C16.7276 23.2812 16.0314 22.1555 15.5573 20.9317C15.0724 19.7098 14.8217 18.4077 14.8184 17.0932C14.8241 15.7761 15.0848 14.4726 15.5861 13.2547C16.5823 10.7701 18.5061 8.77056 20.9504 7.67923C22.2363 7.1065 23.6308 6.81843 25.0384 6.83476C26.4698 6.81644 27.8869 7.1215 29.1839 7.72722C30.3865 8.2667 31.472 9.03603 32.3795 9.99193C33.2613 10.9692 33.9544 12.1015 34.4235 13.3314C34.9001 14.555 35.1441 15.8568 35.1432 17.1699C35.1408 18.4864 34.8834 19.7899 34.3851 21.0084C33.3853 23.4865 31.4718 25.4859 29.04 26.5934C27.7603 27.1913 26.3644 27.4993 24.952 27.4955ZM19.6261 17.1315C19.6245 17.906 19.741 18.6761 19.9715 19.4154C20.1906 20.1314 20.5386 20.8013 20.9983 21.3923C21.4504 21.9701 22.0236 22.4418 22.6777 22.7741C23.3925 23.1297 24.1826 23.3074 24.9808 23.2923C25.8012 23.3116 26.6132 23.1235 27.3415 22.7453C27.9948 22.3949 28.5644 21.9071 29.0112 21.3155C29.4466 20.7156 29.7715 20.043 29.9708 19.3291C30.2086 18.6003 30.338 17.8404 30.3547 17.074C30.3492 16.2986 30.2262 15.5286 29.99 14.7901C29.77 14.0901 29.4182 13.4387 28.9536 12.8708C28.4962 12.3058 27.9237 11.8445 27.2743 11.5177C26.5659 11.1489 25.7795 10.9547 24.9808 10.9516C24.1685 10.9296 23.3636 11.111 22.6393 11.4793C21.9917 11.8216 21.423 12.2956 20.9696 12.8708C20.5347 13.4463 20.2094 14.0969 20.0099 14.7901C19.7647 15.5467 19.6352 16.3362 19.6261 17.1315Z" fill="#141416" />
                        <path d="M44.7497 27.6119C43.5737 27.6323 42.4056 27.4169 41.3142 26.9786C40.3541 26.588 39.4851 26.0032 38.7616 25.2609C38.0732 24.5427 37.5406 23.69 37.1974 22.7562C36.4811 20.8344 36.4811 18.7188 37.1974 16.797C37.5406 15.8632 38.0732 15.0105 38.7616 14.2923C39.487 13.5499 40.3553 12.9623 41.3142 12.565C43.5209 11.7075 45.9688 11.7075 48.1755 12.565C49.1276 12.9646 49.9892 13.5521 50.7089 14.2923C51.4 15.0121 51.9386 15.864 52.2923 16.797C52.6571 17.7495 52.8425 18.7614 52.8393 19.7814C52.8443 20.7967 52.6655 21.8046 52.3115 22.7562C51.9647 23.6905 51.429 24.5431 50.7377 25.2609C50.0178 26.0029 49.1519 26.5878 48.1947 26.9786C47.1 27.4169 45.9287 27.6323 44.7497 27.6119ZM41.3334 19.7814C41.2799 20.8276 41.6225 21.8555 42.293 22.6603C42.596 23.0112 42.9742 23.2893 43.3996 23.4738C43.8249 23.6582 44.2864 23.7442 44.7497 23.7255C45.2071 23.7303 45.6598 23.6319 46.0739 23.4376C46.4784 23.2458 46.838 22.9711 47.1295 22.6315C47.4402 22.2604 47.6779 21.8338 47.8301 21.3744C48.0068 20.8621 48.0945 20.3233 48.0892 19.7814C48.1399 18.7356 47.7976 17.7087 47.1295 16.9025C46.8366 16.5597 46.4711 16.2863 46.0595 16.102C45.6479 15.9178 45.2005 15.8274 44.7497 15.8373C44.2943 15.8341 43.8436 15.9303 43.4293 16.1192C43.0149 16.3082 42.6468 16.5853 42.3506 16.9313C42.0376 17.2988 41.7966 17.722 41.6405 18.1788C41.4474 18.6916 41.3435 19.2336 41.3334 19.7814Z" fill="#141416" />
                        <path d="M64.621 27.6119C63.5772 27.6384 62.5447 27.3902 61.627 26.8922C60.8078 26.4177 60.1417 25.7183 59.7078 24.8769V33.456H55.1016V12.2099H59.1032V14.6569C59.6273 13.8259 60.3451 13.1344 61.1952 12.6417C62.1056 12.1427 63.1322 11.8943 64.17 11.922C65.1406 11.9116 66.1012 12.1181 66.9817 12.5266C67.8252 12.9202 68.5795 13.4819 69.1985 14.1771C69.8343 14.9046 70.329 15.7442 70.6571 16.653C71.0161 17.6365 71.1949 18.6768 71.1849 19.7238C71.1954 20.7895 71.03 21.8498 70.6955 22.8617C70.3902 23.7675 69.9219 24.6098 69.3136 25.3472C68.748 26.041 68.0415 26.6069 67.2408 27.0073C66.4275 27.4128 65.5298 27.6199 64.621 27.6119ZM63.0664 23.7734C63.5447 23.7771 64.0163 23.6617 64.4387 23.4375C64.8499 23.2183 65.2148 22.9218 65.5135 22.5642C65.8296 22.1845 66.0706 21.7481 66.2236 21.2784C66.3865 20.783 66.4675 20.2644 66.4635 19.743C66.4705 19.2158 66.3794 18.6918 66.1948 18.198C66.0239 17.7484 65.77 17.335 65.4463 16.9792C65.1252 16.6305 64.7323 16.3555 64.2948 16.1731C63.8331 15.9793 63.3368 15.8814 62.8361 15.8853C62.187 15.8886 61.5531 16.0821 61.0128 16.4418C60.4315 16.811 59.9659 17.3365 59.6694 17.9581V21.0672C59.9681 21.8143 60.4499 22.4743 61.0704 22.9865C61.6264 23.4629 62.3343 23.7249 63.0664 23.7254V23.7734Z" fill="#141416" />
                        <path d="M79.4486 27.6121C78.1438 27.6136 76.8473 27.403 75.6101 26.9883C74.439 26.6219 73.3595 26.0101 72.4434 25.1938L74.0843 22.3725C74.9252 22.9615 75.8298 23.454 76.7809 23.8407C77.5766 24.1811 78.4299 24.3667 79.2951 24.3877C79.7764 24.4153 80.2557 24.3055 80.6769 24.071C80.8394 23.9721 80.9712 23.83 81.0576 23.6606C81.144 23.4911 81.1817 23.301 81.1663 23.1114C81.1631 22.9102 81.1039 22.7139 80.9955 22.5445C80.887 22.375 80.7335 22.239 80.5522 22.1518C79.8592 21.8124 79.1278 21.5579 78.3738 21.3937C77.3566 21.1058 76.4546 20.8275 75.802 20.5588C75.1925 20.3388 74.6148 20.0387 74.0843 19.6664C73.6722 19.3819 73.3415 18.9949 73.1247 18.5436C72.9226 18.071 72.8245 17.5605 72.8368 17.0466C72.826 16.3374 72.9757 15.6349 73.2749 14.9918C73.574 14.3486 74.0148 13.7815 74.5641 13.3328C75.1356 12.8737 75.786 12.5225 76.4834 12.2964C77.2572 12.0411 78.0677 11.9147 78.8824 11.9222C79.9616 11.9142 81.0357 12.0695 82.0684 12.3828C83.1352 12.7297 84.1164 13.2988 84.9473 14.0526L83.1624 16.8163C82.444 16.3021 81.6718 15.8677 80.8593 15.5208C80.2504 15.2673 79.5994 15.1306 78.94 15.1177C78.4988 15.1022 78.0615 15.2049 77.6733 15.4152C77.507 15.512 77.3707 15.6528 77.2793 15.8222C77.1878 15.9915 77.1449 16.1827 77.1551 16.3749C77.1471 16.5723 77.1963 16.7679 77.2967 16.9381C77.3972 17.1084 77.5446 17.246 77.7213 17.3345C78.3856 17.6465 79.0846 17.8784 79.8037 18.0254C80.7269 18.2644 81.6367 18.5527 82.529 18.8891C83.1694 19.1228 83.7735 19.4459 84.3235 19.8487C84.7549 20.1694 85.1009 20.5912 85.3311 21.077C85.5535 21.5948 85.6616 22.1546 85.6478 22.718C85.6747 23.4068 85.5362 24.0921 85.2441 24.7165C84.9519 25.3408 84.5145 25.8862 83.9684 26.307C82.6546 27.2443 81.0599 27.7048 79.4486 27.6121Z" fill="#141416" />
                        <path d="M87.8262 27.3234V22.6309H91.5975V27.3234H87.8262Z" fill="#141416" />
                        <path d="M94.709 27.3234V22.6309H98.4899V27.3234H94.709Z" fill="#141416" />
                        <path d="M101.59 27.3234V22.6309H105.361V27.3234H101.59Z" fill="#141416" />
                        <path d="M198.998 254.964C196.627 254.83 185.15 253.621 183.145 251.778C177.675 246.75 174.508 243.487 174.038 240.387C173.596 237.633 175.765 232.423 175.813 229.592C175.861 226.761 180.669 228.306 182.319 228.133C185.736 227.788 186.494 229.764 188.288 230.407C189.536 230.858 193.787 228.574 196.79 230.724C199.794 232.874 199.19 236.53 199.19 236.53C199.19 236.53 206.185 239.735 206.31 243.554C206.444 247.872 201.665 255.118 198.998 254.964Z" fill="#3772FF" />
                        <path opacity="0.3" d="M198.998 254.964C196.627 254.83 185.15 253.621 183.145 251.778C177.675 246.75 174.508 243.487 174.038 240.387C173.596 237.633 175.765 232.423 175.813 229.592C175.861 226.761 180.669 228.306 182.319 228.133C185.736 227.788 186.494 229.764 188.288 230.407C189.536 230.858 193.787 228.574 196.79 230.724C199.794 232.874 199.19 236.53 199.19 236.53C199.19 236.53 206.185 239.735 206.31 243.554C206.444 247.872 201.665 255.118 198.998 254.964Z" fill="white" />
                        <path d="M64.2458 345.007C60.7624 349.075 46.5312 351.877 38.1728 345.323C29.8145 338.769 24.8532 316.985 25.074 309.644C25.2947 302.303 46.7519 212.473 52.8551 191.073C58.9583 169.673 49.0166 159.031 42.0209 153.974C35.0253 148.917 20.2279 154.425 17.2338 157.621C14.2398 160.816 12.8867 169.29 12.8867 169.29C12.8867 169.29 15.3625 161.613 19.8824 159.07C24.4022 156.527 34.3343 154.723 39.5355 157.659C44.7367 160.595 52.3561 173.675 48.0858 185.104C43.8154 196.533 17.8768 300.211 17.2338 309.903C16.4949 321.544 23.0491 342.051 32.9813 350.908C42.9134 359.766 59.9659 358.931 61.77 359.545C63.5741 360.159 63.8428 362.788 63.8428 362.788L64.2458 345.007Z" fill="#3772FF" />
                        <path opacity="0.1" d="M64.2458 345.007C60.7624 349.075 46.5312 351.877 38.1728 345.323C29.8145 338.769 24.8532 316.985 25.074 309.644C25.2947 302.303 46.7519 212.473 52.8551 191.073C58.9583 169.673 49.0166 159.031 42.0209 153.974C35.0253 148.917 20.2279 154.425 17.2338 157.621C14.2398 160.816 12.8867 169.29 12.8867 169.29C12.8867 169.29 15.3625 161.613 19.8824 159.07C24.4022 156.527 34.3343 154.723 39.5355 157.659C44.7367 160.595 52.3561 173.675 48.0858 185.104C43.8154 196.533 17.8768 300.211 17.2338 309.903C16.4949 321.544 23.0491 342.051 32.9813 350.908C42.9134 359.766 59.9659 358.931 61.77 359.545C63.5741 360.159 63.8428 362.788 63.8428 362.788L64.2458 345.007Z" fill="black" />
                        <g opacity="0.2">
                            <path opacity="0.2" d="M58.9677 349.18C58.4154 352.497 58.3185 355.874 58.6798 359.217H58.296C58.0612 357.594 57.9649 355.953 58.0081 354.314C58.0523 352.575 58.2514 350.844 58.603 349.141C58.6798 348.786 58.7566 348.431 58.8429 348.076L59.198 347.961C59.1404 348.345 59.0349 348.738 58.9677 349.18Z" fill="black" />
                            <path opacity="0.2" d="M46.5123 348.854L46.3972 349.516C46.1133 351.212 45.9307 352.923 45.8502 354.641C45.8502 353.777 45.783 352.914 45.8502 352.05C45.9173 351.186 45.9461 350.323 46.0709 349.459L46.1572 348.787L46.5123 348.854Z" fill="black" />
                            <path opacity="0.2" d="M48.4405 357.932C48.4405 358.115 48.4405 358.287 48.4405 358.47C48.4405 358.287 48.3829 358.105 48.3638 357.923C48.2582 357.251 48.1622 356.579 48.1047 355.898C48.1047 355.034 47.9895 354.17 48.0183 353.297C48.0237 351.896 48.1392 350.497 48.3638 349.113H48.834C48.5922 350.49 48.4415 351.881 48.3829 353.278C48.3095 354.829 48.3287 356.383 48.4405 357.932Z" fill="black" />
                            <path opacity="0.2" d="M52.1738 355.974C52.1738 356.844 52.1738 357.717 52.1738 358.594L51.7324 358.527C51.7324 357.663 51.7324 356.809 51.7324 355.955C51.8123 354.224 52.1024 352.51 52.5961 350.85C52.3123 352.543 52.1711 354.257 52.1738 355.974Z" fill="black" />
                            <path opacity="0.2" d="M42.5407 347.778C42.368 348.584 42.2144 349.4 42.1089 350.216C41.8531 351.912 41.7216 353.625 41.7154 355.34C41.7154 355.571 41.7154 355.791 41.7154 356.022L41.3796 355.897C41.3796 355.714 41.3796 355.523 41.3796 355.34C41.3217 353.605 41.4181 351.867 41.6675 350.149C41.7903 349.296 41.9568 348.451 42.1665 347.615L42.5407 347.778Z" fill="black" />
                            <path opacity="0.2" d="M32.8177 338.461C32.6961 338.711 32.5809 338.96 32.4722 339.21C31.2583 341.881 30.366 344.687 29.814 347.568L29.6797 347.405C29.6797 347.088 29.7565 346.772 29.8044 346.445C29.958 345.601 30.0923 344.737 30.3226 343.902C30.752 342.219 31.3235 340.576 32.0308 338.989C32.1939 338.634 32.3666 338.279 32.5394 337.934L32.8177 338.461Z" fill="black" />
                            <path opacity="0.2" d="M34.1912 340.803C33.8937 341.542 33.6346 342.29 33.4043 343.048C32.9023 344.678 32.5238 346.343 32.2719 348.029C32.1568 348.662 32.0608 349.286 31.9936 349.948C31.9056 349.863 31.8223 349.773 31.7441 349.679C31.7695 349.117 31.824 348.557 31.9073 348C32.1329 346.275 32.5086 344.573 33.03 342.914C33.3083 342.05 33.6154 341.196 33.9897 340.361C34.0185 340.496 34.1144 340.649 34.1912 340.803Z" fill="black" />
                            <path opacity="0.2" d="M36.7513 346.09C36.2417 347.735 35.8725 349.42 35.6477 351.128C35.5709 351.694 35.5038 352.26 35.4558 352.826L35.0527 352.577C35.1103 352.088 35.1871 351.617 35.283 351.099C35.5705 349.377 36.0635 347.695 36.7513 346.09Z" fill="black" />
                            <path opacity="0.2" d="M31.0523 334.853C30.4303 335.695 29.8537 336.57 29.325 337.473C28.4423 338.942 27.6445 340.461 26.9355 342.022C27.2234 341.216 27.5017 340.39 27.8952 339.594C28.2886 338.797 28.6149 338.02 29.0467 337.271C29.5907 336.269 30.2065 335.307 30.8892 334.393C30.9084 334.537 30.9756 334.7 31.0523 334.853Z" fill="black" />
                            <path opacity="0.2" d="M26.6199 321.17C26.2648 321.487 25.9098 321.803 25.5643 322.13C24.3153 323.31 23.1301 324.556 22.0137 325.863C22.5223 325.172 22.9733 324.452 23.5683 323.79C24.1632 323.128 24.691 322.465 25.3148 321.87C25.6986 321.467 26.1017 321.084 26.5143 320.729C26.5143 320.911 26.5911 321.016 26.6199 321.17Z" fill="black" />
                            <path opacity="0.2" d="M27.2623 323.788C26.4079 324.602 25.6036 325.467 24.8537 326.379C23.7462 327.694 22.7145 329.072 21.7637 330.505C22.1859 329.747 22.5889 328.979 23.0592 328.259C23.5294 327.54 24.0188 326.81 24.5658 326.139C25.3467 325.116 26.2032 324.154 27.128 323.26C27.176 323.442 27.2143 323.615 27.2623 323.788Z" fill="black" />
                            <path opacity="0.2" d="M28.5368 328.155C27.7211 328.978 26.9522 329.846 26.2337 330.755C25.1653 332.089 24.2028 333.504 23.3548 334.987C23.1917 335.256 23.0381 335.525 22.8846 335.793L22.6543 335.256C22.7407 335.083 22.8462 334.911 22.9422 334.738C23.819 333.243 24.824 331.827 25.9458 330.506C26.7266 329.597 27.5734 328.747 28.4792 327.963L28.5368 328.155Z" fill="black" />
                            <path opacity="0.2" d="M25.3996 314.499C23.7368 314.566 22.0815 314.758 20.4479 315.075C19.4211 315.257 18.3943 315.478 17.3867 315.756C17.3867 315.612 17.3867 315.468 17.3867 315.325C18.3463 315.037 19.3827 314.806 20.3999 314.605C22.0457 314.296 23.7157 314.136 25.39 314.125C25.3708 314.25 25.3804 314.375 25.3996 314.499Z" fill="black" />
                            <path opacity="0.2" d="M26.3796 301.834C26.3796 301.988 26.3124 302.141 26.2837 302.295C25.0659 302.228 23.8453 302.228 22.6275 302.295C21.2393 302.341 19.855 302.469 18.4819 302.679C18.4774 302.643 18.4774 302.608 18.4819 302.573C18.9905 302.448 19.4895 302.333 20.0077 302.247C20.8618 302.122 21.7254 301.978 22.5891 301.92C23.8491 301.804 25.1156 301.776 26.3796 301.834Z" fill="black" />
                            <path opacity="0.2" d="M25.9354 304.087L25.8491 304.538C25.3788 304.538 24.8894 304.538 24.4384 304.538C22.7242 304.562 21.0144 304.716 19.3236 304.999C18.863 305.066 18.412 305.143 17.9609 305.229C17.9609 305.124 17.9609 305.018 18.0281 304.913C18.4312 304.807 18.8438 304.711 19.2564 304.634C20.9607 304.294 22.6913 304.101 24.4288 304.059C24.9278 304.059 25.4364 304.068 25.9354 304.087Z" fill="black" />
                            <path opacity="0.2" d="M22.6169 308.675C20.8994 308.723 19.1887 308.913 17.5021 309.241L17.291 309.289C17.291 309.174 17.291 309.039 17.291 308.905H17.387C19.1147 308.619 20.8707 308.541 22.6169 308.675Z" fill="black" />
                            <path opacity="0.2" d="M27.1652 297.993C27.1652 298.108 27.1172 298.214 27.098 298.319C26.8005 298.319 26.4935 298.252 26.196 298.233C24.4869 298.108 22.771 298.108 21.062 298.233C20.4958 298.233 19.9392 298.31 19.373 298.377C19.373 298.214 19.4498 298.06 19.4786 297.907C19.9968 297.849 20.515 297.801 21.0332 297.763C22.7675 297.654 24.508 297.699 26.2344 297.897L27.1652 297.993Z" fill="black" />
                            <path opacity="0.2" d="M29.7676 285.998C29.7676 286.151 29.7004 286.314 29.662 286.468C28.0476 286.107 26.4085 285.866 24.7584 285.748C23.9235 285.671 23.079 285.633 22.2441 285.633L22.3017 285.393C23.127 285.393 23.9523 285.326 24.7871 285.393C26.4613 285.455 28.1273 285.657 29.7676 285.998Z" fill="black" />
                            <path opacity="0.2" d="M29.2744 288.214C29.2744 288.358 29.2072 288.502 29.1784 288.636C28.2266 288.453 27.2656 288.322 26.2996 288.243C24.7703 288.075 23.2309 288.017 21.6934 288.07C21.6934 287.935 21.7605 287.801 21.7893 287.667C23.309 287.588 24.8325 287.62 26.3475 287.763C27.3304 287.861 28.3074 288.012 29.2744 288.214Z" fill="black" />
                            <path opacity="0.2" d="M23.9788 292.12C22.9167 292.02 21.8497 291.981 20.7832 292.005C20.8011 291.914 20.8235 291.825 20.8504 291.736C21.902 291.777 22.9484 291.906 23.9788 292.12Z" fill="black" />
                            <path opacity="0.2" d="M32.636 273.33C32.636 273.446 32.636 273.551 32.5592 273.666C31.0047 273.35 29.4213 273.186 27.8379 273.014C28.7016 273.014 29.5652 273.014 30.4289 273.081C31.2925 273.148 31.9067 273.206 32.636 273.33Z" fill="black" />
                            <path opacity="0.2" d="M32.1273 275.548C32.1273 275.701 32.0601 275.845 32.0218 275.989C31.0621 275.816 30.1025 275.701 29.0757 275.624C27.5954 275.493 26.1086 275.448 24.623 275.49C24.6273 275.465 24.6273 275.439 24.623 275.413C25.2372 275.346 25.861 275.279 26.4847 275.25C27.3484 275.25 28.212 275.193 29.0853 275.25C30.1042 275.291 31.1199 275.39 32.1273 275.548Z" fill="black" />
                            <path opacity="0.2" d="M31.3873 279.972L31.1282 279.914C29.5479 279.579 27.9435 279.37 26.3301 279.29C25.4856 279.29 24.6412 279.223 23.7871 279.233C23.7871 279.089 23.8543 278.945 23.8927 278.801C24.7179 278.801 25.5432 278.849 26.3589 278.926C27.9888 279.068 29.5979 279.389 31.157 279.885L31.3873 279.972Z" fill="black" />
                            <path opacity="0.2" d="M33.5945 269.195C33.5945 269.349 33.5178 269.502 33.489 269.646C33.1243 269.56 32.7597 269.493 32.395 269.435C30.7129 269.119 29.0097 268.926 27.2994 268.859C26.9443 268.859 26.5797 268.859 26.2246 268.859C26.2246 268.754 26.2246 268.639 26.3014 268.533H27.261C28.9954 268.541 30.726 268.698 32.4334 269.003C32.8556 269.032 33.2299 269.09 33.5945 269.195Z" fill="black" />
                            <path opacity="0.2" d="M37.27 253.487C37.27 253.582 37.27 253.678 37.2028 253.774C36.032 253.563 34.8325 253.419 33.6426 253.275C34.4775 253.275 35.3123 253.275 36.1568 253.362L37.27 253.487Z" fill="black" />
                            <path opacity="0.2" d="M35.8699 259.426C35.8699 259.56 35.8027 259.695 35.7739 259.82L34.9198 259.637C33.237 259.29 31.5357 259.041 29.8242 258.889C30.6783 258.889 31.5515 258.889 32.4152 258.956C33.2789 259.023 34.1425 259.109 34.987 259.273L35.8699 259.426Z" fill="black" />
                            <path opacity="0.2" d="M39.9466 242.153C39.9466 242.307 39.8698 242.451 39.841 242.595C38.5576 242.177 37.2501 241.837 35.9257 241.578C34.9661 241.367 34.0065 241.213 33.0469 241.098L33.1045 240.887H33.4403C34.304 240.973 35.1677 241.04 36.0217 241.194C37.3524 241.414 38.6645 241.735 39.9466 242.153Z" fill="black" />
                            <path opacity="0.2" d="M38.9309 246.482C38.9309 246.626 38.8637 246.77 38.8253 246.914C38.1536 246.713 37.4627 246.54 36.7717 246.396C35.1734 246.025 33.5508 245.768 31.916 245.628L32.0024 245.254C33.6433 245.347 35.2738 245.575 36.8773 245.935C37.5682 246.06 38.2495 246.271 38.9309 246.482Z" fill="black" />
                            <path opacity="0.2" d="M42.827 225.13C41.2139 224.537 39.5545 224.078 37.8658 223.758L37.4531 223.681C37.4531 223.556 37.5203 223.431 37.5491 223.307L37.9425 223.393C39.6319 223.783 41.2709 224.366 42.827 225.13Z" fill="black" />
                            <path opacity="0.2" d="M40.9068 238.381L40.8204 238.717L40.3981 238.573C38.7722 238.027 37.1073 237.603 35.4177 237.307C34.9763 237.22 34.5252 237.144 34.0742 237.086L34.1894 236.645L35.5041 236.836C37.2151 237.15 38.8947 237.616 40.5229 238.228L40.9068 238.381Z" fill="black" />
                            <path opacity="0.2" d="M53.8442 187.012C53.8481 187.054 53.8481 187.096 53.8442 187.137C52.6302 186.555 51.3801 186.052 50.1017 185.63C49.4491 185.41 48.7774 185.208 48.1152 185.026C48.1717 184.891 48.2198 184.754 48.2592 184.613C48.9213 184.815 49.5835 185.036 50.1784 185.285C51.4464 185.758 52.6726 186.335 53.8442 187.012Z" fill="black" />
                            <path opacity="0.2" d="M51.5774 190.506C50.0185 189.911 48.4139 189.442 46.7793 189.105C46.7793 188.99 46.8561 188.885 46.8849 188.77C48.5098 189.169 50.0841 189.751 51.5774 190.506Z" fill="black" />
                            <path opacity="0.2" d="M52.1041 165.862C50.8086 166.044 49.5131 166.313 48.2656 166.582C49.0909 166.332 49.9162 166.054 50.7606 165.853L52.0178 165.574C52.0178 165.67 52.0657 165.766 52.1041 165.862Z" fill="black" />
                            <path opacity="0.2" d="M53.1039 168.329C52.5473 168.329 51.9907 168.415 51.4437 168.482C50.1867 168.611 48.9374 168.806 47.7012 169.068C47.7051 169.026 47.7051 168.984 47.7012 168.943C48.0946 168.818 48.4977 168.703 48.8911 168.607C49.7356 168.425 50.58 168.223 51.4437 168.118C51.9619 168.031 52.4897 167.964 53.0079 167.926C53.0481 168.058 53.0801 168.193 53.1039 168.329Z" fill="black" />
                            <path opacity="0.2" d="M54.7063 180.259C54.7063 180.354 54.7063 180.45 54.7063 180.556C53.526 180.278 52.3265 180.067 51.127 179.855C51.981 179.923 52.8543 179.971 53.7083 180.095L54.7063 180.259Z" fill="black" />
                            <path opacity="0.2" d="M52.529 172.695C51.2861 172.709 50.0454 172.806 48.8152 172.983C48.8152 172.878 48.8152 172.791 48.748 172.695C50.0055 172.575 51.2715 172.575 52.529 172.695Z" fill="black" />
                            <path opacity="0.2" d="M48.7763 160.395C47.8167 160.846 46.9146 161.354 46.0317 161.882C45.5231 162.17 45.0721 162.477 44.5539 162.842L44.4004 162.612C44.8706 162.256 45.36 161.911 45.8398 161.604C46.6885 161.038 47.5732 160.528 48.4884 160.078L48.7763 160.395Z" fill="black" />
                            <path opacity="0.2" d="M43.633 156.643C42.7453 157.29 41.8961 157.988 41.09 158.735L40.8789 158.552C41.7408 157.838 42.6622 157.199 43.633 156.643Z" fill="black" />
                            <path opacity="0.2" d="M32.8278 152.016C32.2808 153.042 31.801 154.088 31.3596 155.154C31.2156 155.499 31.0813 155.845 30.947 156.2H30.4863C30.6591 155.787 30.8318 155.384 31.0141 154.981C31.4841 153.959 32.0194 152.969 32.6167 152.016H32.8278Z" fill="black" />
                            <path opacity="0.2" d="M29.1512 153.273C28.6183 154.324 28.1536 155.407 27.7597 156.517L27.4238 156.575C27.8956 155.423 28.4739 154.318 29.1512 153.273Z" fill="black" />
                            <path opacity="0.2" d="M25.6769 153.359C25.581 154.319 25.5234 155.279 25.4658 156.305C25.4658 155.442 25.3794 154.578 25.4082 153.714C25.4082 153.618 25.4082 153.532 25.4082 153.436L25.6769 153.359Z" fill="black" />
                            <path opacity="0.2" d="M23.0376 157.687L22.673 157.773C22.5208 156.692 22.431 155.602 22.4043 154.51L22.8649 154.318C22.8586 155.443 22.9163 156.568 23.0376 157.687Z" fill="black" />
                            <path opacity="0.2" d="M20.6871 156.285C20.6103 157.082 20.5719 157.888 20.5719 158.684L20.332 158.799C20.3925 157.954 20.5111 157.114 20.6871 156.285Z" fill="black" />
                            <path opacity="0.2" d="M43.0286 154.733C42.0298 155.409 41.0687 156.139 40.1498 156.92L39.4013 157.573C39.2752 157.496 39.1437 157.429 39.0078 157.371C39.3053 157.122 39.6028 156.872 39.9099 156.633C40.8476 155.897 41.8351 155.226 42.8655 154.627L43.0286 154.733Z" fill="black" />
                            <path opacity="0.2" d="M47.182 158.521C46.2224 159.135 45.3491 159.807 44.4375 160.441C45.0804 159.874 45.7138 159.279 46.3567 158.742L46.9517 158.291L47.182 158.521Z" fill="black" />
                            <path opacity="0.2" d="M43.2857 228.497L43.1706 228.958L42.8827 228.853C41.2698 228.264 39.6145 227.799 37.931 227.461C37.48 227.355 37.029 227.269 36.5684 227.192C36.5684 227.096 36.5684 227.01 36.6355 226.914C37.0962 226.962 37.5952 227.02 38.0078 227.106C39.7187 227.389 41.4014 227.823 43.0362 228.401L43.2857 228.497Z" fill="black" />
                            <path opacity="0.2" d="M46.0796 217.184C46.0752 217.251 46.0752 217.319 46.0796 217.386L43.9492 216.771C44.621 216.906 45.3503 217.021 46.0796 217.184Z" fill="black" />
                            <path opacity="0.2" d="M45.0056 221.481L44.9192 221.875L43.9596 221.606C42.2924 221.195 40.6042 220.875 38.9023 220.646C39.7564 220.704 40.6297 220.742 41.4837 220.848C42.3378 220.954 43.2015 221.088 44.0459 221.299C44.3434 221.318 44.6793 221.395 45.0056 221.481Z" fill="black" />
                            <path opacity="0.2" d="M50.5508 199.595L50.4357 200.027C49.8983 199.873 49.3609 199.739 48.8235 199.624C47.246 199.242 45.6422 198.979 44.0254 198.837C44.0254 198.703 44.0926 198.578 44.1309 198.453C45.746 198.562 47.3502 198.796 48.9291 199.154C49.4761 199.288 50.0135 199.432 50.5508 199.595Z" fill="black" />
                            <path opacity="0.2" d="M47.1445 212.914L47.0294 213.356C46.0586 213.047 45.072 212.791 44.0737 212.588C42.8962 212.317 41.7044 212.112 40.5039 211.974C40.5083 211.926 40.5083 211.878 40.5039 211.83C40.8494 211.83 41.1852 211.83 41.5307 211.878C42.3848 211.974 43.2484 212.051 44.1025 212.224C45.13 212.39 46.146 212.62 47.1445 212.914Z" fill="black" />
                            <path opacity="0.2" d="M49.544 203.433C49.547 203.475 49.547 203.516 49.544 203.558L48.2773 203.184L49.544 203.433Z" fill="black" />
                            <path opacity="0.2" d="M50.6369 193.808C49.0553 193.26 47.4312 192.842 45.7812 192.56C45.7812 192.445 45.8388 192.33 45.8772 192.215C47.5197 192.561 49.1168 193.096 50.6369 193.808Z" fill="black" />
                            <path opacity="0.2" d="M34.9187 263.465C34.9084 263.53 34.8924 263.594 34.8708 263.656C33.8322 263.362 32.7782 263.125 31.7136 262.946C30.409 262.708 29.0923 262.541 27.7695 262.447C27.7695 262.303 27.8367 262.15 27.8751 262.006C29.1888 262.106 30.4939 262.299 31.7808 262.582C32.8442 262.81 33.8923 263.105 34.9187 263.465Z" fill="black" />
                        </g>
                        <path d="M97.5254 386.165C100.303 385.786 103.116 385.747 105.903 386.05C109.041 386.337 112.105 387.175 114.952 388.526C115.787 388.929 116.516 387.691 115.682 387.288C109.895 384.606 103.434 383.73 97.1416 384.774C96.2299 384.918 96.6234 386.309 97.5254 386.165Z" fill="#141416" />
                        <path d="M94.7725 386.482C97.7983 386.777 100.71 387.79 103.265 389.438C105.985 391.051 108.37 393.172 110.29 395.685C110.817 396.434 112.065 395.685 111.527 394.956C109.488 392.235 106.923 389.95 103.985 388.238C101.212 386.462 98.0521 385.379 94.7725 385.081C93.8128 385.033 93.8128 386.473 94.7725 386.521V386.482Z" fill="#141416" />
                        <path d="M158.366 370.809C161.14 370.429 163.95 370.391 166.734 370.694C169.875 370.987 172.94 371.824 175.793 373.17C176.618 373.573 177.347 372.335 176.513 371.932C170.725 369.264 164.268 368.398 157.982 369.446C157.071 369.6 157.454 370.982 158.366 370.838V370.809Z" fill="#141416" />
                        <path d="M155.563 371.119C158.591 371.413 161.504 372.43 164.056 374.084C166.778 375.691 169.163 377.809 171.081 380.322C171.608 381.07 172.856 380.322 172.319 379.593C170.277 376.874 167.712 374.59 164.776 372.875C162.001 371.103 158.842 370.02 155.563 369.718C154.604 369.67 154.604 371.109 155.563 371.157V371.119Z" fill="#141416" />
                        <path d="M119.357 193.192C119.357 193.192 96.9399 192.77 80.799 179.575C67.4507 168.645 62.5662 163.262 59.6297 165.949C56.6933 168.636 61.002 212.682 96.0955 222.567C108.043 225.916 119.357 204.334 119.357 204.334V193.192Z" fill="#3772FF" />
                        <path opacity="0.1" d="M119.357 193.192C119.357 193.192 96.9399 192.77 80.799 179.575C67.4507 168.645 62.5662 163.262 59.6297 165.949C56.6933 168.636 61.002 212.682 96.0955 222.567C108.043 225.916 119.357 204.334 119.357 204.334V193.192Z" fill="#3772FF" />
                        <path opacity="0.1" d="M119.359 193.194V204.306C119.359 204.306 108.083 225.907 96.0974 222.539C87.4019 220.113 79.6188 215.168 73.7285 208.327C82.1156 202.847 94.7539 194.633 101.855 190.219C107.55 191.906 113.426 192.905 119.359 193.194Z" fill="black" />
                        <path d="M94.0124 269.225C94.0124 269.225 80.3281 243.488 81.2686 230.917C82.209 218.346 95.6054 197.119 106.737 191.784C117.869 186.448 169.756 194.068 186.894 211.744C190.608 215.582 187.077 222.348 185.167 225.812C182.691 230.293 174.035 240.389 166.531 243.316C166.531 243.316 167.875 258.103 152.137 266.126C152.137 266.126 157.482 291.268 162.357 304.943C167.232 318.617 179.016 341.408 171.78 357.914C164.545 374.419 146.341 373.162 134.355 374.89C122.369 376.617 102.323 388.248 84.5697 385.733C66.8166 383.219 59.7634 363.509 64.763 342.023C70.2713 318.464 79.5508 289.819 94.0124 269.225Z" fill="#3772FF" />
                        <path d="M97.6318 214.575C97.6318 214.575 90.9145 213.942 77.9307 208.702C64.947 203.463 43.4802 189.663 22.6084 200.737C-1.49741 213.52 -9.54867 271.471 36.4366 289.387C76.1074 304.837 104.34 246.204 104.34 246.204L97.6318 214.575Z" fill="#3772FF" />
                        <path opacity="0.1" d="M104.33 246.223C104.33 246.223 76.1074 304.837 36.4365 289.407C26.0725 285.376 18.4531 279.302 13.1464 272.133C8.30169 263.879 5.37922 254.639 4.59614 245.101C3.79965 233.729 5.55577 217.272 20.3052 208.165C35.0546 199.058 53.94 207.675 68.7375 215.669C83.5349 223.663 96.7201 224.306 100.069 225.975C107.343 233.125 104.33 246.223 104.33 246.223Z" fill="black" />
                        <path d="M68.7383 204.586L75.8779 207.388C78.2674 208.3 80.676 209.24 83.0367 210.113C85.3974 210.987 87.8348 211.85 90.2531 212.628C91.4622 213.04 92.6809 213.376 93.9188 213.712C94.533 213.866 95.1472 214.038 95.7709 214.182C96.3947 214.326 97.0184 214.432 97.6902 214.576C95.1196 214.437 92.5715 214.022 90.0899 213.338C87.5986 212.7 85.1446 211.925 82.7392 211.015C80.3305 210.113 77.9411 209.096 75.6284 208.06C73.3157 207.023 70.9358 205.862 68.7383 204.586Z" fill="#141416" />
                        <path d="M90.8105 267.219L92.5379 264.599L94.2844 262.008L97.7486 256.817C98.9002 255.08 100.013 253.333 101.136 251.577C101.683 250.694 102.249 249.821 102.758 248.909C103.266 247.998 103.804 247.115 104.36 246.213C103.641 248.192 102.778 250.117 101.779 251.971C100.819 253.823 99.6871 255.608 98.5451 257.364C97.4032 259.12 96.1749 260.818 94.8794 262.459C93.6062 264.115 92.2481 265.704 90.8105 267.219Z" fill="#141416" />
                        <path d="M91.8145 213.431C92.1578 213.2 92.5372 213.028 92.9372 212.922C94.124 212.564 95.394 212.591 96.5646 212.999C96.152 213.047 95.7585 213.047 95.3651 213.086L94.1847 213.172C93.3978 213.191 92.6205 213.287 91.8145 213.431Z" fill="#141416" />
                        <path d="M101.422 251.818C101.966 251.163 102.556 250.547 103.188 249.975C103.802 249.399 104.387 248.872 105.02 248.238C104.841 249.12 104.367 249.916 103.677 250.493C103.043 251.111 102.27 251.565 101.422 251.818Z" fill="#141416" />
                        <path d="M101.423 234.948L94.4941 234.698C92.1814 234.622 89.8687 234.545 87.5656 234.516C82.9498 234.449 78.3244 234.372 73.7374 234.718C69.1504 235.063 64.8321 236.685 60.6193 238.489C58.5081 239.391 56.4353 240.408 54.4009 241.492C52.3665 242.577 50.3321 243.69 48.3457 244.87C52.1214 242.175 56.1255 239.815 60.3122 237.817C64.4962 235.702 69.0163 234.332 73.6702 233.768C78.3052 233.523 82.9498 233.523 87.5848 233.768C92.2102 234.017 96.8259 234.382 101.423 234.948Z" fill="#141416" />
                        <path d="M97.6316 236.627C94.8103 237.174 91.9986 237.731 89.2061 238.354C86.4136 238.978 83.6403 239.669 80.9342 240.542C79.5867 240.965 78.2691 241.478 76.9901 242.078C75.7202 242.692 74.4981 243.401 73.3339 244.198C72.1603 245.001 71.0502 245.893 70.0136 246.866C68.972 247.83 68.0093 248.877 67.1348 249.995C68.6462 247.529 70.5979 245.363 72.8925 243.603C74.051 242.713 75.2875 241.93 76.5871 241.262C77.8974 240.625 79.2505 240.079 80.6367 239.631C83.3944 238.767 86.21 238.1 89.0622 237.635C91.8972 237.136 94.7583 236.799 97.6316 236.627Z" fill="#141416" />
                        <path d="M174.498 249.468C174.285 245.26 175.492 241.104 177.924 237.664C178.48 236.916 177.223 236.196 176.686 236.935C175.348 238.79 174.372 240.88 173.807 243.096C173.247 245.171 172.995 247.318 173.059 249.468C173.116 250.427 174.556 250.427 174.498 249.468Z" fill="#141416" />
                        <path d="M175.668 250.474C176.16 248.477 177.15 246.638 178.547 245.128C180.141 243.283 182.102 241.789 184.305 240.743C185.14 240.359 184.411 239.121 183.576 239.495C182.89 239.827 182.229 240.209 181.599 240.637C180.054 241.65 178.653 242.867 177.434 244.255C175.918 245.908 174.839 247.913 174.296 250.09C174.123 251.001 175.505 251.385 175.678 250.474H175.668Z" fill="#141416" />
                        <path d="M176.215 251.711C177.086 250.18 178.318 248.886 179.804 247.94C181.662 246.609 183.865 245.846 186.147 245.742C186.338 245.742 186.521 245.666 186.656 245.531C186.791 245.396 186.867 245.213 186.867 245.022C186.867 244.832 186.791 244.649 186.656 244.514C186.521 244.379 186.338 244.303 186.147 244.303C183.607 244.4 181.15 245.234 179.075 246.702C177.379 247.774 175.969 249.243 174.968 250.982C174.555 251.807 175.793 252.536 176.215 251.711Z" fill="#141416" />
                        <path d="M132.006 273.391C132.006 273.391 156.487 277.45 172.762 248.978C172.762 248.978 176.284 246.963 177.138 250.801C177.138 250.801 178.99 278.889 162.388 291.537C145.537 304.367 125.846 300.414 125.846 300.414C125.846 300.414 113.371 283.39 132.006 273.391Z" fill="#3772FF" />
                        <path d="M152.685 269.225C150.523 270.906 148.032 272.116 145.373 272.775C144.053 273.12 142.709 273.36 141.352 273.495C139.995 273.62 138.63 273.62 137.273 273.495L141.237 272.727C142.542 272.468 143.837 272.151 145.075 271.816C146.313 271.48 147.628 271.086 148.914 270.664C150.2 270.242 151.447 269.791 152.685 269.225Z" fill="#141416" />
                        <path d="M125.846 300.414C127.437 300.622 129.039 300.741 130.644 300.769C132.256 300.769 133.849 300.769 135.442 300.682C138.632 300.488 141.798 300.013 144.904 299.262C148.013 298.537 151.044 297.508 153.953 296.191C156.881 294.873 159.696 293.316 162.369 291.537C161.736 292.036 161.102 292.497 160.45 293.025C159.837 293.533 159.196 294.007 158.531 294.445L156.458 295.721C155.776 296.162 155.018 296.479 154.308 296.863C152.859 297.594 151.365 298.235 149.836 298.782C149.078 299.07 148.291 299.272 147.514 299.521L146.353 299.886L145.163 300.164C142.001 300.933 138.763 301.342 135.509 301.383C132.259 301.486 129.01 301.16 125.846 300.414Z" fill="#141416" />
                        <path d="M128.234 301.9C128.697 301.888 129.159 301.846 129.616 301.775C130.077 301.708 130.576 301.66 130.969 301.583C131.871 301.458 132.735 301.305 133.685 301.084C132.986 301.762 132.081 302.187 131.113 302.293C130.632 302.351 130.146 302.351 129.664 302.293C129.162 302.284 128.67 302.149 128.234 301.9Z" fill="#141416" />
                        <path d="M146.734 227.127C146.571 227.021 146.59 227.559 146.734 227.693C150.448 231.013 154.047 235.428 151.638 239.736C151.616 239.768 151.608 239.807 151.616 239.845C151.623 239.883 151.645 239.916 151.676 239.938C151.708 239.96 151.747 239.968 151.785 239.96C151.823 239.953 151.856 239.931 151.878 239.9C155.707 235.504 150.87 229.689 146.734 227.127Z" fill="#141416" />
                        <path d="M140.211 227.289C133.542 232.874 143.618 243.919 149.807 238.737C155.997 233.555 145.806 222.606 140.211 227.289Z" fill="#141416" />
                        <path d="M125.73 233.028C124.865 231.812 124.273 230.424 123.993 228.959C123.679 227.471 123.653 225.937 123.916 224.439C124.187 222.915 124.822 221.48 125.768 220.255C126.683 219.051 127.907 218.116 129.309 217.549C128.445 218.648 127.684 219.823 127.035 221.061C126.442 222.237 126.028 223.495 125.806 224.794C125.576 226.115 125.466 227.455 125.48 228.796C125.499 230.168 125.586 231.559 125.73 233.028Z" fill="#141416" />
                        <path d="M125.009 233.124C123.915 232.109 123.052 230.872 122.475 229.496C121.859 228.107 121.517 226.611 121.468 225.092C121.418 223.542 121.747 222.004 122.427 220.61C123.066 219.246 124.06 218.079 125.306 217.232C124.688 218.474 124.189 219.772 123.819 221.109C123.484 222.383 123.339 223.699 123.387 225.015C123.43 226.356 123.6 227.689 123.896 228.997C124.193 230.37 124.577 231.713 125.009 233.124Z" fill="#141416" />
                        <path opacity="0.5" d="M163.176 368.287C154.539 373.939 142.899 373.661 134.387 374.889C125.875 376.118 113.458 382.259 100.579 384.879C100.387 374.506 101.347 352.252 111.135 331.14C123.821 303.791 141.843 295.356 147.707 317.178C152.226 334.048 152.735 349.191 163.176 368.287Z" fill="white" />
                        <path d="M152.156 266.128C149.477 267.325 146.633 268.114 143.721 268.469C140.794 268.853 137.827 268.815 134.911 268.354C133.452 268.13 132.01 267.809 130.593 267.395C129.192 266.934 127.829 266.366 126.515 265.696C125.856 265.383 125.215 265.034 124.595 264.65L122.753 263.441C122.144 263.03 121.567 262.575 121.026 262.078C120.469 261.608 119.922 261.119 119.375 260.648C121.697 262.374 124.201 263.841 126.841 265.024C128.154 265.61 129.503 266.11 130.881 266.521C132.258 266.913 133.659 267.214 135.074 267.423C137.914 267.871 140.797 267.984 143.663 267.759L145.813 267.529C146.523 267.414 147.243 267.318 147.953 267.174C149.374 266.914 150.778 266.565 152.156 266.128Z" fill="#141416" />
                        <path d="M188.412 217.577C188.412 217.577 178.912 218.182 178.912 215.312C178.912 213.988 186.167 209.555 188.787 209.555C191.406 209.555 188.412 217.577 188.412 217.577Z" fill="#141416" />
                        <path d="M144.115 254.964C147.91 254.964 150.986 251.888 150.986 248.094C150.986 244.299 147.91 241.223 144.115 241.223C140.32 241.223 137.244 244.299 137.244 248.094C137.244 251.888 140.32 254.964 144.115 254.964Z" fill="#EF466F" />
                        <path d="M203.625 373.236C203.625 371.893 206.12 370.204 206.811 369.782C207.502 369.359 210.649 369.148 210.861 368.611C211.072 368.074 214.104 367.44 215.217 367.498C216.33 367.555 217.827 369.513 218.039 371.058C218.143 371.806 217.974 372.567 217.563 373.201C217.153 373.836 216.528 374.301 215.803 374.513C214.421 374.781 211.897 376.058 210.841 376.163C209.786 376.269 203.625 374.733 203.625 373.236Z" fill="#3772FF" />
                        <path opacity="0.3" d="M203.625 373.236C203.625 371.893 206.12 370.204 206.811 369.782C207.502 369.359 210.649 369.148 210.861 368.611C211.072 368.074 214.104 367.44 215.217 367.498C216.33 367.555 217.827 369.513 218.039 371.058C218.143 371.806 217.974 372.567 217.563 373.201C217.153 373.836 216.528 374.301 215.803 374.513C214.421 374.781 211.897 376.058 210.841 376.163C209.786 376.269 203.625 374.733 203.625 373.236Z" fill="white" />
                        <path d="M192.836 368.64C192.145 367.681 193.709 366.942 194.486 365.819C195.264 364.696 197.874 365.147 198.373 365.502C199.024 366.412 199.317 367.529 199.198 368.64C198.466 369.682 197.358 370.397 196.108 370.636C195.235 370.636 193.268 369.216 192.836 368.64Z" fill="#3772FF" />
                        <path opacity="0.3" d="M192.836 368.64C192.145 367.681 193.709 366.942 194.486 365.819C195.264 364.696 197.874 365.147 198.373 365.502C199.024 366.412 199.317 367.529 199.198 368.64C198.466 369.682 197.358 370.397 196.108 370.636C195.235 370.636 193.268 369.216 192.836 368.64Z" fill="white" />
                        <path d="M205.101 366.778C205.283 365.252 208.258 365.694 208.728 366.519C209.198 367.344 207.481 368.956 206.703 368.285C205.926 367.613 205.005 367.344 205.101 366.778Z" fill="#3772FF" />
                        <path opacity="0.3" d="M205.101 366.778C205.283 365.252 208.258 365.694 208.728 366.519C209.198 367.344 207.481 368.956 206.703 368.285C205.926 367.613 205.005 367.344 205.101 366.778Z" fill="white" />
                        <path d="M194.449 375.712C194.929 375.117 196.368 374.983 196.589 375.328C196.81 375.674 197.28 376.364 196.935 376.643C196.589 376.921 195.62 377.545 195.179 377.199C194.737 376.854 194.219 376.029 194.449 375.712Z" fill="#3772FF" />
                        <path opacity="0.3" d="M194.449 375.712C194.929 375.117 196.368 374.983 196.589 375.328C196.81 375.674 197.28 376.364 196.935 376.643C196.589 376.921 195.62 377.545 195.179 377.199C194.737 376.854 194.219 376.029 194.449 375.712Z" fill="white" />
                        <path d="M184.71 371.029C184.988 370.07 186.37 369.436 187.253 369.331C188.136 369.225 189.777 369.954 189.681 370.578C189.585 371.202 188.337 371.749 188.126 372.2C187.915 372.651 187.262 373.62 186.61 373.361C185.957 373.102 184.393 372.094 184.71 371.029Z" fill="#3772FF" />
                        <path opacity="0.3" d="M184.71 371.029C184.988 370.07 186.37 369.436 187.253 369.331C188.136 369.225 189.777 369.954 189.681 370.578C189.585 371.202 188.337 371.749 188.126 372.2C187.915 372.651 187.262 373.62 186.61 373.361C185.957 373.102 184.393 372.094 184.71 371.029Z" fill="white" />
                    </svg>
                    :
                    <svg width="350" height="352" viewBox="0 0 397 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M47.283 259.627L33.0422 259.896L25.8354 260.011C23.4267 260.011 21.0372 260.011 18.5902 260.011H18.168V259.579V247.459V247.018H18.6478L32.9942 247.085C37.7923 247.085 42.5904 247.19 47.3885 247.238H47.6476V247.497C47.6476 249.551 47.5613 251.595 47.5229 253.62L47.283 259.627ZM47.283 259.627L47.139 253.514C47.139 251.499 47.0335 249.493 47.0143 247.507L47.283 247.766C42.4849 247.824 37.6867 247.92 32.8886 247.929L18.5902 247.977L19.07 247.497L19.022 259.617L18.5902 259.195C20.9605 259.195 23.3212 259.195 25.6914 259.243L32.8214 259.358L47.283 259.627Z" fill="#353945" />
                        <path d="M31.3113 274.913L17.08 275.182L9.86365 275.297C7.455 275.297 5.06553 275.297 2.61848 275.297H2.19625V274.865L2.13867 262.745V262.266H2.61848L16.9169 262.371C21.715 262.371 26.5131 262.486 31.2633 262.534H31.5224V262.793C31.5224 264.847 31.4456 266.891 31.3976 268.916L31.3113 274.913ZM31.3113 274.913L31.1769 268.801C31.1769 266.785 31.0714 264.78 31.0522 262.793L31.3113 263.052C26.5131 263.11 21.715 263.206 16.9649 263.216L2.61848 263.273L3.0983 262.793L3.05032 274.913L2.61848 274.491C4.98876 274.491 7.34944 274.491 9.71971 274.539L16.8593 274.654L31.3113 274.913Z" fill="#353945" />
                        <path d="M31.3125 12.5903L17.0429 12.8686L9.82654 12.9741C7.41788 12.9741 5.02842 12.9741 2.58137 13.0317H2.15914V12.5999L2.10156 0.479812V0H2.58137L16.9182 0.0479805C21.7163 0.0479805 26.5144 0.163137 31.2646 0.211119H31.5237V0.470218C31.5237 2.52382 31.4469 4.56781 31.3989 6.59262L31.3125 12.5903ZM31.3125 12.5903L31.1782 6.47747C31.1782 4.46226 31.0726 2.45664 31.0534 0.470218L31.3125 0.729313C26.5144 0.729313 21.7163 0.882855 16.9661 0.892451L2.61976 0.95003L3.09957 0.470218L3.05159 12.5903L2.61976 12.168C4.99003 12.168 7.35071 12.168 9.72098 12.168L16.8606 12.2832L31.3125 12.5903Z" fill="#353945" />
                        <path d="M393.992 12.5903L379.751 12.8686L372.544 12.9741C370.136 12.9741 367.746 12.9741 365.299 13.0317H364.877V12.5999V0.479812V0H365.357L379.703 0.0575788C384.501 0.0575788 389.299 0.172732 394.05 0.220713H394.309V0.479812C394.309 2.53341 394.222 4.57741 394.184 6.60222L393.992 12.5903ZM393.992 12.5903L393.848 6.47747C393.848 4.46226 393.742 2.45664 393.723 0.470218L393.992 0.729313C389.194 0.729313 384.396 0.882855 379.646 0.892451L365.261 0.95003L365.741 0.470218L365.693 12.5903L365.261 12.168C367.631 12.168 369.992 12.168 372.362 12.168L379.492 12.2832L393.992 12.5903Z" fill="#353945" />
                        <path d="M377.987 27.879L363.756 28.1477L356.539 28.2533C354.131 28.2533 351.741 28.2533 349.294 28.3108H348.872V27.879L348.814 15.7493V15.2695H349.294L363.641 15.3271C368.439 15.3271 373.237 15.4423 377.987 15.4902H378.256V15.7493C378.256 17.8029 378.179 19.8469 378.131 21.8717L377.987 27.879ZM377.987 27.879L377.853 21.7662C377.805 19.7414 377.747 17.7454 377.728 15.7589L377.987 16.018C373.189 16.018 368.391 16.1716 363.641 16.1812L349.294 16.2388L349.774 15.7589L349.726 27.879L349.294 27.4568C351.665 27.4568 354.025 27.4568 356.396 27.4568L363.535 27.5719L377.987 27.879Z" fill="#353945" />
                        <path d="M304.509 169.71L290.277 169.979L283.061 170.084C280.652 170.084 278.263 170.084 275.826 170.084H275.394V169.652L275.346 157.523V157.043H275.835L290.172 157.11C294.97 157.11 299.768 157.216 304.518 157.264H304.806V157.523C304.806 159.576 304.729 161.62 304.681 163.645L304.509 169.71ZM304.509 169.71L304.374 163.588C304.326 161.572 304.269 159.576 304.25 157.58L304.509 157.849C299.711 157.849 294.912 158.003 290.162 158.003L275.826 158.06L276.296 157.58L276.248 169.71L275.826 169.278C278.186 169.278 280.547 169.278 282.917 169.336L290.057 169.441L304.509 169.71Z" fill="#353945" />
                        <path d="M288.493 184.996L274.262 185.265L267.055 185.37C264.646 185.418 262.257 185.37 259.81 185.418H259.378V184.986L259.33 172.857V172.377H259.81L274.156 172.444C278.954 172.444 283.753 172.55 288.493 172.598H288.762V172.857C288.762 174.91 288.675 176.954 288.637 178.979L288.493 184.996ZM288.493 184.996L288.359 178.874C288.359 176.858 288.253 174.862 288.234 172.866L288.493 173.135C283.695 173.183 278.897 173.289 274.156 173.289L259.81 173.346L260.29 172.866L260.232 184.996L259.81 184.564C262.171 184.564 264.541 184.564 266.911 184.622L274.041 184.727L288.493 184.996Z" fill="#353945" />
                        <path d="M272.563 169.711L258.332 169.98L251.125 170.085C248.707 170.085 246.327 170.085 243.88 170.085H243.448V169.653L243.391 157.581V157.102H243.88L258.217 157.169C263.015 157.169 267.813 157.274 272.563 157.322H272.832V157.581C272.832 159.635 272.746 161.679 272.698 163.704L272.563 169.711ZM272.563 169.711L272.429 163.589C272.429 161.573 272.323 159.577 272.304 157.581L272.563 157.85C267.765 157.85 262.967 158.004 258.217 158.004L243.88 158.061L244.36 157.581L244.35 169.711L243.928 169.279C246.289 169.279 248.649 169.279 251.029 169.337L258.159 169.442L272.563 169.711Z" fill="#353945" />
                        <path d="M393.992 274.913L379.751 275.182L372.544 275.297C370.136 275.297 367.746 275.297 365.299 275.297H364.877V274.865V262.745V262.266H365.357L379.655 262.371C384.453 262.371 389.251 262.486 394.002 262.534H394.261V262.793C394.261 264.847 394.174 266.891 394.136 268.916L393.992 274.913ZM393.992 274.913L393.848 268.801C393.848 266.785 393.742 264.78 393.723 262.793L393.992 263.052C389.194 263.11 384.396 263.206 379.646 263.216L365.299 263.273L365.779 262.793L365.731 274.913L365.299 274.491C367.669 274.491 370.03 274.491 372.4 274.539L379.53 274.654L393.992 274.913Z" fill="#353945" />
                        <path d="M150.239 48.6428L135.998 48.9115L128.792 49.0171C126.383 49.0171 123.993 49.0171 121.546 49.0747H121.124V48.6428L121.076 36.5228V36.043H121.556L135.902 36.1005C140.701 36.1005 145.499 36.2061 150.297 36.2637H150.556V36.5228C150.556 38.5764 150.469 40.6204 150.431 42.6356L150.239 48.6428ZM150.239 48.6428L150.095 42.53C150.095 40.5052 149.99 38.5092 149.97 36.5228L150.239 36.7819C145.441 36.7819 140.643 36.9354 135.845 36.9354L121.518 37.0026L121.997 36.5228L121.949 48.6428L121.518 48.2206C123.888 48.2206 126.249 48.2206 128.619 48.2206L135.749 48.3262L150.239 48.6428Z" fill="#353945" />
                        <path d="M0 341.376L49.5742 341.136L99.1484 341.06L198.287 340.896L297.426 341.05L347 341.136L396.575 341.376L347 341.607L297.426 341.693L198.287 341.847L99.1484 341.693L49.5742 341.607L0 341.376Z" fill="#23262F" />
                        <path d="M130.08 399.999C191.081 399.999 240.533 392.179 240.533 382.534C240.533 372.888 191.081 365.068 130.08 365.068C69.0784 365.068 19.627 372.888 19.627 382.534C19.627 392.179 69.0784 399.999 130.08 399.999Z" fill="#23262F" />
                        <path d="M364.823 341.377H218.883C218.883 321.628 211.436 221.338 291.815 221.338C372.193 221.338 364.823 321.628 364.823 341.377Z" fill="#353945" />
                        <path d="M319.422 226.213C317.023 226.002 314.519 225.887 311.928 225.887C234.572 225.887 241.731 322.377 241.731 341.378H218.873C218.873 321.628 211.436 221.338 291.804 221.338C301.237 221.183 310.612 222.838 319.422 226.213V226.213Z" fill="#23262F" />
                        <path d="M77.3763 183.568V151.248H14.041V125.444L86.7038 32.1777H106.434V125.261H125.521V151.248H106.434V183.568H77.3763ZM43.1081 125.223H80.6294V76.2053L43.1081 125.223Z" fill="#FCFCFD" />
                        <path d="M334.382 183.568V151.248H271.047V125.444L343.7 32.1777H363.44V125.261H382.536V151.248H363.449V183.568H334.382ZM300.114 125.223H337.635V76.2053L300.114 125.223Z" fill="#FCFCFD" />
                        <path d="M163.118 42.0149C172.072 37.1209 180.065 38.829 185.497 38.7522C190.928 38.6755 201.954 31.1616 208.288 32.2939C214.621 33.4263 245.675 48.3773 250.588 55.0946C255.501 61.812 263.677 78.8262 264.445 82.2616C265.213 85.6971 263.197 105.101 264.138 109.544C265.078 113.987 262.027 121.222 260.127 124.332C258.227 127.441 253.649 139.081 252.517 141.202C251.385 143.323 222.279 166.325 212.088 167.774C201.897 169.223 197.933 166.334 196.82 165.701C195.707 165.068 199.066 157.822 193.778 156.105C188.491 154.387 186.514 153.523 186.514 153.523C186.514 153.523 185.017 149.08 182.234 148.034C179.451 146.988 175.517 149.954 175.517 149.954C175.517 149.954 174.327 150.616 172.014 149.55C169.701 148.485 166.458 149.915 166.458 149.915C165.67 148.478 164.378 147.384 162.83 146.844C160.134 145.885 156.977 151.585 156.977 151.585C156.977 151.585 139.348 136.845 138.379 133.227C137.41 129.609 137.419 124.802 136.066 120.32C134.713 115.839 131.959 113.737 131.911 106.214C131.863 98.6904 133.466 82.799 135.443 79.0277C137.419 75.2564 139.147 69.9592 140.385 68.2991C141.623 66.6389 153.858 47.0722 163.118 42.0149Z" fill="#3772FF" />
                        <g opacity="0.3">
                            <path opacity="0.3" d="M163.118 42.013C172.072 37.1189 180.065 38.827 185.497 38.7503C190.928 38.6735 201.954 31.1596 208.288 32.292C214.621 33.4244 245.675 48.3753 250.588 55.0927C255.501 61.8101 263.677 78.8242 264.445 82.2597C265.213 85.6951 263.197 105.099 264.138 109.542C265.078 113.985 262.027 121.22 260.127 124.33C258.227 127.439 253.649 139.079 252.517 141.2C251.385 143.321 222.279 166.323 212.088 167.772C201.897 169.221 197.933 166.332 196.82 165.699C195.707 165.066 199.066 157.821 193.778 156.103C188.491 154.385 186.514 153.521 186.514 153.521C186.514 153.521 185.017 149.078 182.234 148.032C179.451 146.986 175.517 149.952 175.517 149.952C175.517 149.952 174.327 150.614 172.014 149.549C169.701 148.483 166.458 149.913 166.458 149.913C165.67 148.476 164.378 147.382 162.83 146.842C160.134 145.883 156.977 151.583 156.977 151.583C156.977 151.583 139.348 136.843 138.379 133.225C137.41 129.608 137.419 124.8 136.066 120.318C134.713 115.837 131.959 113.735 131.911 106.212C131.863 98.6884 133.466 82.797 135.443 79.0257C137.419 75.2544 139.147 69.9573 140.385 68.2971C141.623 66.637 153.858 47.0702 163.118 42.013Z" fill="white" />
                        </g>
                        <path d="M223.24 139.186C224.717 137.382 228.489 137.084 228.93 139.022C229.372 140.961 228.364 143.197 225.802 143.139C223.24 143.082 222.664 139.915 223.24 139.186Z" fill="#3772FF" />
                        <path opacity="0.1" d="M225.515 143.111C223.221 142.823 222.694 139.887 223.269 139.186C223.719 138.702 224.266 138.319 224.875 138.062C225.484 137.806 226.14 137.682 226.801 137.699C226.139 137.939 224.546 138.754 224.306 139.743C224.066 140.731 223.989 142.42 225.515 143.111Z" fill="black" />
                        <path d="M142.63 100.311C145.164 95.3499 153.484 97.9697 154.223 102.442C154.962 106.913 150.509 110.119 147.313 109.888C144.118 109.658 139.876 105.743 142.63 100.311Z" fill="#3772FF" />
                        <path d="M234.862 106.588C230.88 105.341 220.871 107.903 218.836 116.511C217.675 121.424 223.98 131.673 230.438 132.229C236.896 132.786 241.675 126.078 243.115 120.58C244.554 115.081 240.965 108.498 234.862 106.588Z" fill="#3772FF" />
                        <path opacity="0.1" d="M231.983 132.219C231.47 132.272 230.952 132.272 230.438 132.219C223.98 131.662 217.675 121.413 218.836 116.5C220.861 107.864 230.88 105.321 234.862 106.578C235.603 106.824 236.317 107.145 236.992 107.537C231.83 108.497 224.738 113.669 224.153 117.354C223.529 121.308 226.273 131.317 231.983 132.219Z" fill="black" />
                        <path d="M204.045 41.7755C203.719 45.0671 200.206 47.303 197.327 49.5389C194.449 51.7748 188.691 50.8152 184.67 48.9056C180.649 46.9959 178.288 39.1174 178.394 38.532C181.043 38.532 183.422 38.7815 185.447 38.7527C189.18 38.7527 195.581 35.1157 201.271 33.2637C202.452 35.4804 204.294 39.4724 204.045 41.7755Z" fill="#3772FF" />
                        <path opacity="0.1" d="M195.123 50.5669C192.071 51.3154 187.868 50.3942 184.711 48.9068C180.671 46.9875 178.33 39.1186 178.435 38.5332C180.546 38.5332 182.485 38.6963 184.193 38.7443C184.692 40.7979 186.045 45.8264 187.551 47.1698C189.5 48.878 193.098 50.4901 195.123 50.5669Z" fill="black" />
                        <path d="M236.508 69.7486C239.186 67.8293 244.06 69.7486 244.387 73.5103C244.713 77.272 239.809 79.4312 236.508 76.8786C235.943 76.4713 235.483 75.9358 235.166 75.316C234.849 74.6962 234.684 74.0098 234.684 73.3136C234.684 72.6173 234.849 71.931 235.166 71.3112C235.483 70.6914 235.943 70.1558 236.508 69.7486V69.7486Z" fill="#3772FF" />
                        <path opacity="0.1" d="M237.362 72.3011C236.806 74.3739 237.055 75.8325 239.003 77.9341C238.093 77.8075 237.233 77.4434 236.508 76.8785C235.943 76.4713 235.483 75.9357 235.166 75.3159C234.849 74.6961 234.684 74.0097 234.684 73.3135C234.684 72.6172 234.849 71.9309 235.166 71.3111C235.483 70.6913 235.943 70.1557 236.508 69.7485C237.221 69.303 238.036 69.0464 238.875 69.0028C239.714 68.9591 240.551 69.1299 241.306 69.499C241.114 69.4126 237.919 70.2283 237.362 72.3011Z" fill="black" />
                        <path d="M195.956 157.485C195.36 156.841 194.61 156.359 193.777 156.084C188.48 154.347 186.513 153.503 186.513 153.503C186.513 153.503 185.016 149.06 182.233 148.014C179.45 146.968 175.516 149.933 175.516 149.933C176.159 144.473 178.49 142.256 182.425 140.183C186.359 138.111 193.278 139.051 195.409 144.377C196.925 148.1 196.368 154.127 195.956 157.485Z" fill="#3772FF" />
                        <path opacity="0.1" d="M190.047 139.665C188.44 139.744 186.868 140.164 185.435 140.897C184.002 141.63 182.742 142.659 181.737 143.916C181.056 145.119 180.628 146.448 180.48 147.822C178.695 148.145 177.011 148.884 175.566 149.981C176.209 144.521 178.541 142.304 182.476 140.231C184.838 139.094 187.542 138.892 190.047 139.665V139.665Z" fill="black" />
                        <path d="M167.83 65.8235C160.873 69.662 156.161 80.3426 156.42 83.9029C156.679 87.4631 159.05 93.1632 162.121 95.879C169.03 101.992 182.656 99.1897 185.967 95.879C189.278 92.5683 193.001 82.3962 191.821 77.4062C189.854 69.0671 174.941 61.9178 167.83 65.8235Z" fill="#3772FF" />
                        <path opacity="0.1" d="M177.437 99.1807C172.264 99.958 166.084 99.3918 162.083 95.8796C159.012 93.1638 156.632 87.4733 156.383 83.913C156.133 80.3528 160.835 69.6434 167.792 65.8241C169.98 64.6246 172.898 64.4711 175.949 65.0756C172.36 67.7146 166.439 72.5895 165.144 76.8598C163.225 83.0878 164.367 89.5844 167.063 93.3557C168.848 95.7644 173.972 97.9332 177.437 99.1807Z" fill="black" />
                        <path d="M152.707 110.627C151.395 111.515 149.838 111.972 148.255 111.933C144.522 111.683 139.57 107.134 142.785 100.763C143.372 99.6609 144.289 98.7701 145.407 98.2145C146.526 97.6589 147.789 97.466 149.022 97.6629C152.391 97.9604 155.807 100.244 156.315 103.248C156.519 104.698 156.285 106.175 155.642 107.49C154.999 108.806 153.977 109.898 152.707 110.627V110.627Z" fill="#3772FF" />
                        <path opacity="0.1" d="M152.707 110.627C151.395 111.515 149.838 111.972 148.255 111.933C144.522 111.683 139.57 107.134 142.785 100.763C143.372 99.6609 144.289 98.7701 145.407 98.2145C146.526 97.6589 147.789 97.466 149.022 97.6629C147.573 98.7665 145.289 101.012 145.289 104.16C145.289 108.775 148.168 109.917 149.943 110.541C150.845 110.788 151.792 110.818 152.707 110.627V110.627Z" fill="black" />
                        <path d="M230.541 81.7829C230.205 82.0193 229.797 82.1298 229.387 82.095C228.978 82.0601 228.594 81.882 228.303 81.5922C228.012 81.3023 227.832 80.9191 227.796 80.5099C227.759 80.1008 227.868 79.6919 228.103 79.355C228.249 79.0841 228.474 78.8648 228.749 78.7273C229.024 78.5899 229.335 78.541 229.639 78.5873C230.044 78.5996 230.435 78.7399 230.756 78.9881C231.077 79.2364 231.31 79.5798 231.424 79.9692C231.475 80.3252 231.419 80.6884 231.261 81.0118C231.104 81.3352 230.853 81.6039 230.541 81.7829V81.7829Z" fill="#3772FF" />
                        <path opacity="0.1" d="M230.541 81.7829C230.205 82.0193 229.797 82.1298 229.387 82.095C228.978 82.0601 228.594 81.882 228.303 81.5922C228.012 81.3023 227.832 80.9191 227.796 80.5099C227.759 80.1008 227.868 79.6919 228.103 79.355C228.249 79.0841 228.474 78.8648 228.749 78.7273C229.024 78.5899 229.335 78.541 229.639 78.5873C229.367 78.7635 229.139 78.9995 228.973 79.2773C228.807 79.5551 228.706 79.8673 228.679 80.1899C228.649 80.5456 228.748 80.9003 228.957 81.1893C229.167 81.4782 229.474 81.6821 229.821 81.7637C230.056 81.8297 230.303 81.8363 230.541 81.7829Z" fill="black" />
                        <path d="M153.034 119.619C155.414 118.506 159.406 120.022 158.907 122.21C158.408 124.398 156.316 126.192 153.725 124.897C151.134 123.601 152.065 120.079 153.034 119.619Z" fill="#3772FF" />
                        <path opacity="0.1" d="M157.87 124.189C157.363 124.761 156.673 125.141 155.917 125.263C155.162 125.386 154.387 125.243 153.725 124.861C151.143 123.565 152.055 120.062 153.034 119.583C153.761 119.265 154.558 119.142 155.347 119.228C154.679 119.861 154.249 120.705 154.128 121.617C154.147 123.104 156.066 124.601 157.87 124.189Z" fill="black" />
                        <path d="M166.113 190.132V194.239H151.719V173.799H165.835V177.944H156.44V181.917H164.511V185.756H156.44V190.103L166.113 190.132Z" fill="#FCFCFD" />
                        <path d="M169.002 194.239V173.799H178.214C179.135 173.791 180.046 173.998 180.873 174.404C181.673 174.794 182.392 175.331 182.993 175.987C183.582 176.64 184.053 177.39 184.385 178.204C184.716 178.998 184.889 179.848 184.893 180.708C184.897 181.327 184.816 181.944 184.653 182.541C184.506 183.117 184.287 183.671 184.001 184.192C183.714 184.71 183.355 185.185 182.936 185.602C182.523 186.015 182.055 186.367 181.544 186.648L186.035 194.249H180.709L176.794 187.656H173.723V194.239H169.002ZM173.723 183.53H178.042C178.327 183.525 178.608 183.451 178.858 183.314C179.109 183.176 179.323 182.981 179.481 182.743C179.904 182.151 180.116 181.435 180.086 180.708C180.121 179.973 179.874 179.252 179.395 178.693C179.212 178.47 178.984 178.288 178.726 178.159C178.468 178.03 178.186 177.957 177.898 177.945H173.723V183.53Z" fill="#FCFCFD" />
                        <path d="M188.291 194.239V173.799H197.494C198.418 173.791 199.331 173.998 200.162 174.404C200.962 174.794 201.681 175.331 202.282 175.987C202.871 176.64 203.342 177.39 203.674 178.204C204.002 178.998 204.172 179.849 204.173 180.708C204.173 181.326 204.099 181.941 203.952 182.541C203.793 183.114 203.571 183.668 203.29 184.192C203.005 184.706 202.65 185.177 202.234 185.593C201.822 186.005 201.353 186.357 200.843 186.639L205.324 194.239H200.008L196.093 187.646H193.012V194.239H188.291ZM193.012 183.53H197.331C197.616 183.525 197.897 183.451 198.147 183.314C198.398 183.176 198.612 182.981 198.77 182.743C199.185 182.148 199.394 181.433 199.365 180.708C199.404 179.974 199.16 179.253 198.684 178.693C198.5 178.469 198.271 178.286 198.011 178.157C197.751 178.028 197.467 177.955 197.177 177.945H193.012V183.53Z" fill="#FCFCFD" />
                        <path d="M216.522 194.452C215.091 194.47 213.674 194.165 212.377 193.559C211.169 192.995 210.083 192.199 209.181 191.218C208.298 190.243 207.602 189.114 207.128 187.888C206.643 186.666 206.392 185.364 206.389 184.049C206.388 182.716 206.649 181.396 207.156 180.163C208.157 177.683 210.08 175.688 212.521 174.597C213.806 174.021 215.2 173.73 216.609 173.743C218.043 173.726 219.463 174.031 220.764 174.635C221.972 175.208 223.057 176.01 223.959 176.996C224.838 177.976 225.531 179.107 226.003 180.335C226.473 181.561 226.714 182.862 226.714 184.174C226.717 185.507 226.46 186.827 225.955 188.06C225.461 189.284 224.742 190.403 223.835 191.362C222.916 192.322 221.821 193.097 220.61 193.645C219.323 194.21 217.928 194.486 216.522 194.452V194.452ZM211.206 184.088C211.206 184.864 211.319 185.637 211.542 186.381C211.761 187.094 212.109 187.761 212.569 188.348C213.037 188.959 213.642 189.451 214.336 189.784C215.029 190.118 215.791 190.283 216.561 190.268C217.381 190.285 218.192 190.097 218.921 189.721C219.57 189.371 220.136 188.887 220.582 188.3C221.017 187.7 221.342 187.028 221.541 186.314C221.763 185.579 221.876 184.816 221.877 184.049C221.88 183.274 221.76 182.503 221.522 181.765C221.302 181.065 220.95 180.414 220.486 179.846C220.028 179.281 219.456 178.82 218.806 178.493C218.101 178.125 217.318 177.931 216.522 177.927C215.706 177.907 214.898 178.091 214.171 178.464C213.523 178.806 212.954 179.28 212.501 179.856C212.044 180.445 211.702 181.116 211.494 181.832C211.289 182.566 211.192 183.326 211.206 184.088Z" fill="#FCFCFD" />
                        <path d="M229.449 194.239V173.799H238.662C239.586 173.791 240.499 173.998 241.329 174.404C242.126 174.794 242.843 175.331 243.441 175.987C244.035 176.637 244.51 177.387 244.842 178.204C245.17 178.998 245.339 179.849 245.341 180.708C245.343 181.327 245.265 181.943 245.11 182.541C244.96 183.117 244.737 183.672 244.448 184.192C243.887 185.227 243.036 186.077 242.001 186.639L246.492 194.239H241.166L237.251 187.646H234.171V194.239H229.449ZM234.171 183.53H238.489C238.775 183.525 239.055 183.451 239.306 183.314C239.556 183.176 239.77 182.981 239.928 182.743C240.351 182.151 240.564 181.435 240.533 180.708C240.569 179.973 240.321 179.252 239.842 178.693C239.66 178.469 239.433 178.286 239.174 178.157C238.916 178.028 238.633 177.956 238.345 177.945H234.171V183.53Z" fill="#FCFCFD" />
                        <path d="M24.952 27.4955C23.5206 27.5138 22.1035 27.2087 20.8064 26.603C19.598 26.0354 18.5124 25.2367 17.6109 24.252C16.7276 23.2812 16.0314 22.1555 15.5573 20.9317C15.0724 19.7098 14.8217 18.4077 14.8184 17.0932C14.8241 15.7761 15.0848 14.4726 15.5861 13.2547C16.5823 10.7701 18.5061 8.77056 20.9504 7.67923C22.2363 7.1065 23.6308 6.81843 25.0384 6.83476C26.4698 6.81644 27.8869 7.1215 29.1839 7.72722C30.3865 8.2667 31.472 9.03603 32.3795 9.99193C33.2613 10.9692 33.9544 12.1015 34.4235 13.3314C34.9001 14.555 35.1441 15.8568 35.1432 17.1699C35.1408 18.4864 34.8834 19.7899 34.3851 21.0084C33.3853 23.4865 31.4718 25.4859 29.04 26.5934C27.7603 27.1913 26.3644 27.4993 24.952 27.4955ZM19.6261 17.1315C19.6245 17.906 19.741 18.6761 19.9715 19.4154C20.1906 20.1314 20.5386 20.8013 20.9983 21.3923C21.4504 21.9701 22.0236 22.4418 22.6777 22.7741C23.3925 23.1297 24.1826 23.3074 24.9808 23.2923C25.8012 23.3116 26.6132 23.1235 27.3415 22.7453C27.9948 22.3949 28.5644 21.9071 29.0112 21.3155C29.4466 20.7156 29.7715 20.043 29.9708 19.3291C30.2086 18.6003 30.338 17.8404 30.3547 17.074C30.3492 16.2986 30.2262 15.5286 29.99 14.7901C29.77 14.0901 29.4182 13.4387 28.9536 12.8708C28.4962 12.3058 27.9237 11.8445 27.2743 11.5177C26.5659 11.1489 25.7795 10.9547 24.9808 10.9516C24.1685 10.9296 23.3636 11.111 22.6393 11.4793C21.9917 11.8216 21.423 12.2956 20.9696 12.8708C20.5347 13.4463 20.2094 14.0969 20.0099 14.7901C19.7647 15.5467 19.6352 16.3362 19.6261 17.1315V17.1315Z" fill="#FCFCFD" />
                        <path d="M44.7497 27.6119C43.5737 27.6323 42.4056 27.4169 41.3142 26.9786C40.3541 26.588 39.4851 26.0032 38.7616 25.2609C38.0732 24.5427 37.5406 23.69 37.1974 22.7562C36.4811 20.8344 36.4811 18.7188 37.1974 16.797C37.5406 15.8632 38.0732 15.0105 38.7616 14.2923C39.487 13.5499 40.3553 12.9623 41.3142 12.565C43.5209 11.7075 45.9688 11.7075 48.1755 12.565C49.1276 12.9646 49.9892 13.5521 50.7089 14.2923C51.4 15.0121 51.9386 15.864 52.2923 16.797C52.6571 17.7495 52.8425 18.7614 52.8393 19.7814C52.8443 20.7967 52.6655 21.8046 52.3115 22.7562C51.9647 23.6905 51.429 24.5431 50.7377 25.2609C50.0178 26.0029 49.1519 26.5878 48.1947 26.9786C47.1 27.4169 45.9287 27.6323 44.7497 27.6119V27.6119ZM41.3334 19.7814C41.2799 20.8276 41.6225 21.8555 42.293 22.6603C42.596 23.0112 42.9742 23.2893 43.3996 23.4738C43.8249 23.6582 44.2864 23.7442 44.7497 23.7255C45.2071 23.7303 45.6598 23.6319 46.074 23.4376C46.4784 23.2458 46.838 22.9711 47.1295 22.6315C47.4402 22.2604 47.6779 21.8338 47.8301 21.3744C48.0068 20.8621 48.0945 20.3233 48.0892 19.7814C48.1399 18.7356 47.7976 17.7087 47.1295 16.9025C46.8366 16.5597 46.4711 16.2863 46.0595 16.102C45.6479 15.9178 45.2005 15.8274 44.7497 15.8373C44.2943 15.8341 43.8436 15.9303 43.4293 16.1192C43.0149 16.3082 42.6468 16.5853 42.3506 16.9313C42.0376 17.2988 41.7966 17.722 41.6405 18.1788C41.4474 18.6916 41.3435 19.2336 41.3334 19.7814V19.7814Z" fill="#FCFCFD" />
                        <path d="M64.621 27.6119C63.5772 27.6384 62.5447 27.3902 61.627 26.8922C60.8078 26.4177 60.1417 25.7183 59.7078 24.8769V33.456H55.1016V12.2099H59.1032V14.6569C59.6273 13.8259 60.3451 13.1344 61.1952 12.6417C62.1056 12.1427 63.1322 11.8943 64.17 11.922C65.1406 11.9116 66.1012 12.1181 66.9817 12.5266C67.8252 12.9202 68.5795 13.4819 69.1985 14.1771C69.8343 14.9046 70.329 15.7442 70.6571 16.653C71.0161 17.6365 71.1949 18.6768 71.1849 19.7238C71.1954 20.7895 71.03 21.8498 70.6955 22.8617C70.3902 23.7675 69.9219 24.6098 69.3136 25.3472C68.748 26.041 68.0415 26.6069 67.2408 27.0073C66.4275 27.4128 65.5298 27.6199 64.621 27.6119V27.6119ZM63.0664 23.7734C63.5447 23.7771 64.0163 23.6617 64.4387 23.4375C64.8499 23.2183 65.2148 22.9218 65.5135 22.5642C65.8296 22.1845 66.0706 21.7481 66.2236 21.2784C66.3865 20.783 66.4675 20.2644 66.4635 19.743C66.4705 19.2158 66.3794 18.6918 66.1948 18.198C66.0239 17.7484 65.77 17.335 65.4463 16.9792C65.1252 16.6305 64.7323 16.3555 64.2948 16.1731C63.8331 15.9793 63.3368 15.8814 62.8361 15.8853C62.187 15.8886 61.5531 16.0821 61.0128 16.4418C60.4315 16.811 59.9659 17.3365 59.6694 17.9581V21.0672C59.9681 21.8143 60.4499 22.4743 61.0704 22.9865C61.6264 23.4629 62.3343 23.7249 63.0664 23.7254V23.7734Z" fill="#FCFCFD" />
                        <path d="M79.4486 27.6121C78.1438 27.6136 76.8473 27.403 75.6101 26.9883C74.439 26.6219 73.3595 26.0101 72.4434 25.1938L74.0843 22.3725C74.9252 22.9615 75.8298 23.454 76.7809 23.8407C77.5766 24.1811 78.4299 24.3667 79.2951 24.3877C79.7764 24.4153 80.2557 24.3055 80.6769 24.071C80.8394 23.9721 80.9712 23.83 81.0576 23.6606C81.144 23.4911 81.1817 23.301 81.1663 23.1114C81.1631 22.9102 81.1039 22.7139 80.9955 22.5445C80.887 22.375 80.7335 22.239 80.5522 22.1518C79.8592 21.8124 79.1278 21.5579 78.3738 21.3937C77.3566 21.1058 76.4546 20.8275 75.802 20.5588C75.1925 20.3388 74.6148 20.0387 74.0843 19.6664C73.6722 19.3819 73.3415 18.9949 73.1247 18.5436C72.9226 18.071 72.8245 17.5605 72.8368 17.0466C72.826 16.3374 72.9757 15.6349 73.2749 14.9918C73.574 14.3486 74.0148 13.7815 74.5641 13.3328C75.1356 12.8737 75.786 12.5225 76.4834 12.2964C77.2572 12.0411 78.0677 11.9147 78.8824 11.9222C79.9616 11.9142 81.0357 12.0695 82.0684 12.3828C83.1352 12.7297 84.1164 13.2988 84.9473 14.0526L83.1624 16.8163C82.444 16.3021 81.6718 15.8677 80.8593 15.5208C80.2504 15.2673 79.5994 15.1306 78.94 15.1177C78.4988 15.1022 78.0615 15.2049 77.6733 15.4152C77.507 15.512 77.3707 15.6528 77.2793 15.8222C77.1878 15.9915 77.1449 16.1827 77.1551 16.3749C77.1471 16.5723 77.1963 16.7679 77.2967 16.9381C77.3972 17.1084 77.5446 17.246 77.7213 17.3345C78.3856 17.6465 79.0846 17.8784 79.8037 18.0254C80.7269 18.2644 81.6367 18.5527 82.529 18.8891C83.1694 19.1228 83.7735 19.4459 84.3235 19.8487C84.7549 20.1694 85.1009 20.5912 85.3311 21.077C85.5535 21.5948 85.6616 22.1546 85.6478 22.718C85.6747 23.4068 85.5362 24.0921 85.2441 24.7165C84.9519 25.3408 84.5145 25.8862 83.9684 26.307C82.6546 27.2443 81.0599 27.7048 79.4486 27.6121V27.6121Z" fill="#FCFCFD" />
                        <path d="M87.8262 27.3234V22.6309H91.5975V27.3234H87.8262Z" fill="#141416" />
                        <path d="M94.709 27.3234V22.6309H98.4899V27.3234H94.709Z" fill="#141416" />
                        <path d="M101.59 27.3234V22.6309H105.361V27.3234H101.59Z" fill="#141416" />
                        <path d="M198.998 254.964C196.627 254.83 185.15 253.621 183.145 251.778C177.675 246.75 174.508 243.487 174.038 240.387C173.596 237.633 175.765 232.423 175.813 229.592C175.861 226.761 180.669 228.306 182.319 228.133C185.736 227.788 186.494 229.764 188.288 230.407C189.536 230.858 193.787 228.574 196.79 230.724C199.794 232.874 199.19 236.53 199.19 236.53C199.19 236.53 206.185 239.735 206.31 243.554C206.444 247.872 201.665 255.118 198.998 254.964Z" fill="#3772FF" />
                        <path opacity="0.3" d="M198.998 254.964C196.627 254.83 185.15 253.621 183.145 251.778C177.675 246.75 174.508 243.487 174.038 240.387C173.596 237.633 175.765 232.423 175.813 229.592C175.861 226.761 180.669 228.306 182.319 228.133C185.736 227.788 186.494 229.764 188.288 230.407C189.536 230.858 193.787 228.574 196.79 230.724C199.794 232.874 199.19 236.53 199.19 236.53C199.19 236.53 206.185 239.735 206.31 243.554C206.444 247.872 201.665 255.118 198.998 254.964Z" fill="white" />
                        <path d="M64.2458 345.006C60.7624 349.075 46.5312 351.877 38.1728 345.323C29.8145 338.769 24.8532 316.985 25.074 309.644C25.2947 302.303 46.7519 212.473 52.8551 191.073C58.9583 169.673 49.0166 159.031 42.0209 153.974C35.0253 148.917 20.2279 154.425 17.2338 157.621C14.2398 160.816 12.8867 169.29 12.8867 169.29C12.8867 169.29 15.3625 161.613 19.8824 159.07C24.4022 156.527 34.3343 154.723 39.5355 157.659C44.7367 160.595 52.3561 173.675 48.0858 185.104C43.8154 196.533 17.8768 300.211 17.2338 309.903C16.4949 321.544 23.0491 342.051 32.9813 350.908C42.9134 359.766 59.9659 358.931 61.77 359.545C63.5741 360.159 63.8428 362.788 63.8428 362.788L64.2458 345.006Z" fill="#3772FF" />
                        <path opacity="0.1" d="M64.2458 345.006C60.7624 349.075 46.5312 351.877 38.1728 345.323C29.8145 338.769 24.8532 316.985 25.074 309.644C25.2947 302.303 46.7519 212.473 52.8551 191.073C58.9583 169.673 49.0166 159.031 42.0209 153.974C35.0253 148.917 20.2279 154.425 17.2338 157.621C14.2398 160.816 12.8867 169.29 12.8867 169.29C12.8867 169.29 15.3625 161.613 19.8824 159.07C24.4022 156.527 34.3343 154.723 39.5355 157.659C44.7367 160.595 52.3561 173.675 48.0858 185.104C43.8154 196.533 17.8768 300.211 17.2338 309.903C16.4949 321.544 23.0491 342.051 32.9813 350.908C42.9134 359.766 59.9659 358.931 61.77 359.545C63.5741 360.159 63.8428 362.788 63.8428 362.788L64.2458 345.006Z" fill="black" />
                        <g opacity="0.2">
                            <path opacity="0.2" d="M58.9677 349.18C58.4154 352.497 58.3185 355.874 58.6798 359.217H58.296C58.0612 357.594 57.9649 355.953 58.0081 354.314C58.0523 352.575 58.2514 350.844 58.603 349.141C58.6798 348.786 58.7566 348.431 58.8429 348.076L59.198 347.961C59.1404 348.345 59.0349 348.738 58.9677 349.18Z" fill="black" />
                            <path opacity="0.2" d="M46.5123 348.854L46.3972 349.516C46.1133 351.212 45.9307 352.923 45.8502 354.641C45.8502 353.777 45.783 352.914 45.8502 352.05C45.9173 351.186 45.9461 350.323 46.0709 349.459L46.1572 348.787L46.5123 348.854Z" fill="black" />
                            <path opacity="0.2" d="M48.4405 357.932C48.4405 358.115 48.4405 358.287 48.4405 358.47C48.4405 358.287 48.3829 358.105 48.3638 357.923C48.2582 357.251 48.1622 356.579 48.1047 355.898C48.1047 355.034 47.9895 354.17 48.0183 353.297C48.0237 351.896 48.1392 350.497 48.3638 349.113H48.834C48.5922 350.49 48.4415 351.881 48.3829 353.278C48.3095 354.829 48.3287 356.383 48.4405 357.932Z" fill="black" />
                            <path opacity="0.2" d="M52.1738 355.974C52.1738 356.844 52.1738 357.717 52.1738 358.594L51.7324 358.527C51.7324 357.663 51.7324 356.809 51.7324 355.955C51.8123 354.224 52.1024 352.51 52.5961 350.85C52.3123 352.543 52.1711 354.257 52.1738 355.974V355.974Z" fill="black" />
                            <path opacity="0.2" d="M42.5407 347.778C42.368 348.584 42.2144 349.4 42.1089 350.216C41.8531 351.912 41.7216 353.625 41.7154 355.34C41.7154 355.571 41.7154 355.791 41.7154 356.022L41.3796 355.897C41.3796 355.714 41.3796 355.523 41.3796 355.34C41.3217 353.605 41.4181 351.867 41.6675 350.149C41.7903 349.296 41.9568 348.451 42.1665 347.615L42.5407 347.778Z" fill="black" />
                            <path opacity="0.2" d="M32.8177 338.461C32.6961 338.711 32.5809 338.96 32.4722 339.21C31.2583 341.881 30.366 344.687 29.814 347.568L29.6797 347.405C29.6797 347.088 29.7565 346.772 29.8044 346.445C29.958 345.601 30.0923 344.737 30.3226 343.902C30.752 342.219 31.3235 340.576 32.0308 338.989C32.1939 338.634 32.3666 338.279 32.5394 337.934L32.8177 338.461Z" fill="black" />
                            <path opacity="0.2" d="M34.1912 340.803C33.8937 341.542 33.6346 342.29 33.4043 343.048C32.9023 344.678 32.5238 346.343 32.2719 348.029C32.1568 348.662 32.0608 349.286 31.9936 349.948C31.9056 349.863 31.8223 349.773 31.7441 349.679C31.7695 349.117 31.824 348.557 31.9073 348C32.1329 346.275 32.5086 344.573 33.03 342.914C33.3083 342.05 33.6154 341.196 33.9897 340.361C34.0185 340.496 34.1144 340.649 34.1912 340.803Z" fill="black" />
                            <path opacity="0.2" d="M36.7513 346.09C36.2417 347.735 35.8725 349.42 35.6477 351.128C35.5709 351.694 35.5038 352.26 35.4558 352.826L35.0527 352.577C35.1103 352.088 35.1871 351.617 35.283 351.099C35.5705 349.377 36.0635 347.695 36.7513 346.09V346.09Z" fill="black" />
                            <path opacity="0.2" d="M31.0523 334.853C30.4303 335.695 29.8537 336.57 29.325 337.473C28.4423 338.942 27.6445 340.461 26.9355 342.022C27.2234 341.216 27.5017 340.39 27.8952 339.594C28.2886 338.797 28.6149 338.02 29.0467 337.271C29.5907 336.269 30.2065 335.307 30.8892 334.393C30.9084 334.537 30.9756 334.7 31.0523 334.853Z" fill="black" />
                            <path opacity="0.2" d="M26.6199 321.17C26.2648 321.487 25.9098 321.803 25.5643 322.13C24.3153 323.31 23.1301 324.556 22.0137 325.863C22.5223 325.172 22.9733 324.452 23.5683 323.79C24.1632 323.128 24.691 322.465 25.3148 321.87C25.6986 321.467 26.1017 321.084 26.5143 320.729C26.5143 320.911 26.5911 321.016 26.6199 321.17Z" fill="black" />
                            <path opacity="0.2" d="M27.2623 323.788C26.4079 324.602 25.6036 325.467 24.8537 326.379C23.7462 327.694 22.7145 329.072 21.7637 330.505C22.1859 329.747 22.5889 328.979 23.0592 328.259C23.5294 327.54 24.0188 326.81 24.5658 326.139C25.3467 325.116 26.2032 324.154 27.128 323.26C27.176 323.442 27.2143 323.615 27.2623 323.788Z" fill="black" />
                            <path opacity="0.2" d="M28.5368 328.155C27.7211 328.978 26.9522 329.846 26.2337 330.755C25.1653 332.089 24.2028 333.504 23.3548 334.987C23.1917 335.256 23.0381 335.525 22.8846 335.793L22.6543 335.256C22.7407 335.083 22.8462 334.911 22.9422 334.738C23.819 333.243 24.824 331.827 25.9458 330.506C26.7266 329.597 27.5734 328.747 28.4792 327.963L28.5368 328.155Z" fill="black" />
                            <path opacity="0.2" d="M25.3996 314.499C23.7368 314.566 22.0815 314.758 20.4479 315.075C19.4211 315.257 18.3943 315.478 17.3867 315.756C17.3867 315.612 17.3867 315.468 17.3867 315.325C18.3463 315.037 19.3827 314.806 20.3999 314.605C22.0457 314.296 23.7157 314.136 25.39 314.125C25.3708 314.25 25.3804 314.375 25.3996 314.499Z" fill="black" />
                            <path opacity="0.2" d="M26.3796 301.834C26.3796 301.988 26.3124 302.141 26.2837 302.295C25.0659 302.228 23.8453 302.228 22.6275 302.295C21.2393 302.341 19.855 302.469 18.4819 302.679C18.4774 302.643 18.4774 302.608 18.4819 302.573C18.9905 302.448 19.4895 302.333 20.0077 302.247C20.8618 302.122 21.7254 301.978 22.5891 301.92C23.8491 301.804 25.1156 301.776 26.3796 301.834V301.834Z" fill="black" />
                            <path opacity="0.2" d="M25.9354 304.087L25.8491 304.538C25.3788 304.538 24.8894 304.538 24.4384 304.538C22.7242 304.562 21.0144 304.716 19.3236 304.999C18.863 305.066 18.412 305.143 17.9609 305.229C17.9609 305.124 17.9609 305.018 18.0281 304.913C18.4312 304.807 18.8438 304.711 19.2564 304.634C20.9607 304.294 22.6913 304.101 24.4288 304.059C24.9278 304.059 25.4364 304.068 25.9354 304.087Z" fill="black" />
                            <path opacity="0.2" d="M22.6169 308.675C20.8994 308.723 19.1887 308.913 17.5021 309.241L17.291 309.289C17.291 309.174 17.291 309.039 17.291 308.905H17.387C19.1147 308.619 20.8707 308.541 22.6169 308.675V308.675Z" fill="black" />
                            <path opacity="0.2" d="M27.1652 297.993C27.1652 298.108 27.1172 298.214 27.098 298.319C26.8005 298.319 26.4935 298.252 26.196 298.233C24.4869 298.108 22.771 298.108 21.062 298.233C20.4958 298.233 19.9392 298.31 19.373 298.377C19.373 298.214 19.4498 298.06 19.4786 297.907C19.9968 297.849 20.515 297.801 21.0332 297.763C22.7675 297.654 24.508 297.699 26.2344 297.897L27.1652 297.993Z" fill="black" />
                            <path opacity="0.2" d="M29.7676 285.998C29.7676 286.151 29.7004 286.314 29.662 286.468C28.0476 286.107 26.4085 285.866 24.7584 285.748C23.9235 285.671 23.079 285.633 22.2441 285.633L22.3017 285.393C23.127 285.393 23.9523 285.326 24.7871 285.393C26.4613 285.455 28.1273 285.657 29.7676 285.998V285.998Z" fill="black" />
                            <path opacity="0.2" d="M29.2744 288.214C29.2744 288.358 29.2072 288.502 29.1784 288.636C28.2266 288.453 27.2656 288.322 26.2996 288.243C24.7703 288.075 23.2309 288.017 21.6934 288.07C21.6934 287.935 21.7605 287.801 21.7893 287.667C23.309 287.588 24.8325 287.62 26.3475 287.763C27.3304 287.861 28.3074 288.012 29.2744 288.214V288.214Z" fill="black" />
                            <path opacity="0.2" d="M23.9788 292.12C22.9167 292.02 21.8497 291.981 20.7832 292.005C20.8011 291.914 20.8235 291.825 20.8504 291.736C21.902 291.777 22.9484 291.906 23.9788 292.12V292.12Z" fill="black" />
                            <path opacity="0.2" d="M32.636 273.33C32.636 273.446 32.636 273.551 32.5593 273.666C31.0047 273.35 29.4213 273.186 27.8379 273.014C28.7016 273.014 29.5652 273.014 30.4289 273.081C31.2925 273.148 31.9067 273.206 32.636 273.33Z" fill="black" />
                            <path opacity="0.2" d="M32.1273 275.548C32.1273 275.701 32.0601 275.845 32.0218 275.989C31.0621 275.816 30.1025 275.701 29.0757 275.624C27.5954 275.493 26.1086 275.448 24.623 275.49C24.6273 275.465 24.6273 275.439 24.623 275.413C25.2372 275.346 25.861 275.279 26.4847 275.25C27.3484 275.25 28.212 275.193 29.0853 275.25C30.1042 275.291 31.1199 275.39 32.1273 275.548V275.548Z" fill="black" />
                            <path opacity="0.2" d="M31.3873 279.972L31.1282 279.914C29.5479 279.579 27.9435 279.37 26.3301 279.29C25.4856 279.29 24.6412 279.223 23.7871 279.233C23.7871 279.089 23.8543 278.945 23.8927 278.801C24.7179 278.801 25.5432 278.849 26.3589 278.926C27.9888 279.068 29.5979 279.389 31.157 279.885L31.3873 279.972Z" fill="black" />
                            <path opacity="0.2" d="M33.5945 269.195C33.5945 269.349 33.5178 269.502 33.489 269.646C33.1243 269.56 32.7597 269.493 32.395 269.435C30.7129 269.119 29.0097 268.926 27.2994 268.859C26.9443 268.859 26.5797 268.859 26.2246 268.859C26.2246 268.754 26.2246 268.639 26.3014 268.533H27.261C28.9954 268.541 30.726 268.698 32.4334 269.003C32.8556 269.032 33.2299 269.09 33.5945 269.195Z" fill="black" />
                            <path opacity="0.2" d="M37.27 253.487C37.27 253.582 37.27 253.678 37.2028 253.774C36.032 253.563 34.8325 253.419 33.6426 253.275C34.4775 253.275 35.3123 253.275 36.1568 253.362L37.27 253.487Z" fill="black" />
                            <path opacity="0.2" d="M35.8699 259.426C35.8699 259.56 35.8027 259.695 35.7739 259.82L34.9198 259.637C33.237 259.29 31.5357 259.041 29.8242 258.889C30.6783 258.889 31.5515 258.889 32.4152 258.956C33.2789 259.023 34.1425 259.109 34.987 259.273L35.8699 259.426Z" fill="black" />
                            <path opacity="0.2" d="M39.9466 242.153C39.9466 242.307 39.8698 242.451 39.841 242.595C38.5576 242.177 37.2501 241.837 35.9257 241.578C34.9661 241.367 34.0065 241.213 33.0469 241.098L33.1045 240.887H33.4403C34.304 240.973 35.1677 241.04 36.0217 241.194C37.3524 241.414 38.6645 241.735 39.9466 242.153V242.153Z" fill="black" />
                            <path opacity="0.2" d="M38.9309 246.482C38.9309 246.626 38.8637 246.77 38.8253 246.914C38.1536 246.713 37.4627 246.54 36.7717 246.396C35.1734 246.025 33.5508 245.768 31.916 245.628L32.0024 245.254C33.6433 245.347 35.2738 245.575 36.8773 245.935C37.5682 246.06 38.2495 246.271 38.9309 246.482Z" fill="black" />
                            <path opacity="0.2" d="M42.827 225.13C41.2139 224.537 39.5545 224.078 37.8658 223.758L37.4531 223.681C37.4531 223.556 37.5203 223.431 37.5491 223.307L37.9425 223.393C39.6319 223.783 41.2709 224.366 42.827 225.13V225.13Z" fill="black" />
                            <path opacity="0.2" d="M40.9068 238.381L40.8204 238.717L40.3982 238.573C38.7722 238.027 37.1073 237.603 35.4177 237.307C34.9763 237.22 34.5252 237.144 34.0742 237.086L34.1894 236.645L35.5041 236.836C37.2151 237.15 38.8947 237.616 40.5229 238.228L40.9068 238.381Z" fill="black" />
                            <path opacity="0.2" d="M53.8442 187.012C53.8481 187.054 53.8481 187.096 53.8442 187.137C52.6302 186.555 51.3801 186.052 50.1017 185.63C49.4491 185.41 48.7774 185.208 48.1152 185.026C48.1717 184.891 48.2198 184.754 48.2592 184.613C48.9213 184.815 49.5835 185.036 50.1784 185.285C51.4464 185.758 52.6726 186.335 53.8442 187.012V187.012Z" fill="black" />
                            <path opacity="0.2" d="M51.5774 190.506C50.0185 189.911 48.4139 189.442 46.7793 189.105V189.105C46.7793 188.99 46.8561 188.885 46.8849 188.77C48.5098 189.169 50.0841 189.751 51.5774 190.506V190.506Z" fill="black" />
                            <path opacity="0.2" d="M52.1041 165.862C50.8086 166.044 49.5131 166.313 48.2656 166.582C49.0909 166.332 49.9162 166.054 50.7606 165.853L52.0178 165.574C52.0178 165.67 52.0657 165.766 52.1041 165.862Z" fill="black" />
                            <path opacity="0.2" d="M53.1039 168.329C52.5473 168.329 51.9907 168.415 51.4437 168.482C50.1867 168.611 48.9374 168.806 47.7012 169.068C47.7051 169.026 47.7051 168.984 47.7012 168.943C48.0946 168.818 48.4977 168.703 48.8911 168.607C49.7356 168.425 50.58 168.223 51.4437 168.118C51.9619 168.031 52.4897 167.964 53.0079 167.926C53.0481 168.058 53.0801 168.193 53.1039 168.329V168.329Z" fill="black" />
                            <path opacity="0.2" d="M54.7063 180.259C54.7063 180.354 54.7063 180.45 54.7063 180.556C53.526 180.278 52.3265 180.067 51.127 179.855C51.981 179.923 52.8543 179.971 53.7083 180.095L54.7063 180.259Z" fill="black" />
                            <path opacity="0.2" d="M52.529 172.695C51.2861 172.709 50.0454 172.806 48.8152 172.983C48.8152 172.878 48.8152 172.791 48.748 172.695C50.0055 172.575 51.2715 172.575 52.529 172.695V172.695Z" fill="black" />
                            <path opacity="0.2" d="M48.7763 160.395C47.8167 160.846 46.9146 161.354 46.0317 161.882C45.5231 162.17 45.0721 162.477 44.5539 162.842L44.4004 162.612C44.8706 162.256 45.36 161.911 45.8398 161.604C46.6885 161.038 47.5732 160.528 48.4884 160.078L48.7763 160.395Z" fill="black" />
                            <path opacity="0.2" d="M43.633 156.643C42.7453 157.29 41.8961 157.988 41.09 158.735L40.8789 158.552C41.7408 157.838 42.6622 157.199 43.633 156.643V156.643Z" fill="black" />
                            <path opacity="0.2" d="M32.8278 152.016C32.2808 153.042 31.801 154.088 31.3596 155.154C31.2156 155.499 31.0813 155.845 30.947 156.2H30.4863C30.6591 155.787 30.8318 155.384 31.0141 154.981C31.4841 153.959 32.0194 152.969 32.6167 152.016H32.8278Z" fill="black" />
                            <path opacity="0.2" d="M29.1512 153.273C28.6183 154.324 28.1536 155.407 27.7597 156.517L27.4238 156.575C27.8956 155.423 28.4739 154.318 29.1512 153.273V153.273Z" fill="black" />
                            <path opacity="0.2" d="M25.6769 153.359C25.581 154.319 25.5234 155.279 25.4658 156.305C25.4658 155.442 25.3794 154.578 25.4082 153.714C25.4082 153.618 25.4082 153.532 25.4082 153.436L25.6769 153.359Z" fill="black" />
                            <path opacity="0.2" d="M23.0376 157.687L22.673 157.773C22.5208 156.692 22.431 155.602 22.4043 154.51L22.8649 154.318C22.8586 155.443 22.9163 156.568 23.0376 157.687V157.687Z" fill="black" />
                            <path opacity="0.2" d="M20.6871 156.285C20.6103 157.082 20.5719 157.888 20.5719 158.684L20.332 158.799C20.3925 157.954 20.5111 157.114 20.6871 156.285V156.285Z" fill="black" />
                            <path opacity="0.2" d="M43.0286 154.733C42.0298 155.409 41.0687 156.139 40.1498 156.92L39.4013 157.573C39.2752 157.496 39.1437 157.429 39.0078 157.371C39.3053 157.122 39.6028 156.872 39.9099 156.633C40.8476 155.897 41.8351 155.226 42.8655 154.627L43.0286 154.733Z" fill="black" />
                            <path opacity="0.2" d="M47.182 158.521C46.2224 159.135 45.3491 159.807 44.4375 160.441C45.0804 159.874 45.7138 159.279 46.3567 158.742L46.9517 158.291L47.182 158.521Z" fill="black" />
                            <path opacity="0.2" d="M43.2857 228.497L43.1706 228.958L42.8827 228.853C41.2698 228.264 39.6145 227.799 37.931 227.461C37.48 227.355 37.029 227.269 36.5684 227.192C36.5684 227.096 36.5684 227.01 36.6355 226.914C37.0962 226.962 37.5952 227.02 38.0078 227.106C39.7187 227.389 41.4014 227.823 43.0362 228.401L43.2857 228.497Z" fill="black" />
                            <path opacity="0.2" d="M46.0796 217.184C46.0752 217.251 46.0752 217.319 46.0796 217.386L43.9492 216.771C44.621 216.906 45.3503 217.021 46.0796 217.184Z" fill="black" />
                            <path opacity="0.2" d="M45.0056 221.481L44.9192 221.875L43.9596 221.606C42.2924 221.195 40.6042 220.875 38.9023 220.646C39.7564 220.704 40.6297 220.742 41.4837 220.848C42.3378 220.954 43.2015 221.088 44.0459 221.299C44.3434 221.318 44.6793 221.395 45.0056 221.481Z" fill="black" />
                            <path opacity="0.2" d="M50.5508 199.595L50.4357 200.027C49.8983 199.873 49.3609 199.739 48.8235 199.624C47.246 199.242 45.6422 198.979 44.0254 198.837C44.0254 198.703 44.0926 198.578 44.1309 198.453C45.746 198.562 47.3502 198.796 48.9291 199.154C49.4761 199.288 50.0135 199.432 50.5508 199.595Z" fill="black" />
                            <path opacity="0.2" d="M47.1445 212.914L47.0294 213.356C46.0586 213.047 45.072 212.791 44.0737 212.588C42.8962 212.317 41.7044 212.112 40.5039 211.974C40.5083 211.926 40.5083 211.878 40.5039 211.83C40.8494 211.83 41.1852 211.83 41.5307 211.878C42.3848 211.974 43.2484 212.051 44.1025 212.224C45.13 212.39 46.146 212.62 47.1445 212.914V212.914Z" fill="black" />
                            <path opacity="0.2" d="M49.544 203.433C49.547 203.475 49.547 203.516 49.544 203.558L48.2773 203.184L49.544 203.433Z" fill="black" />
                            <path opacity="0.2" d="M50.6369 193.808C49.0553 193.26 47.4312 192.842 45.7812 192.56C45.7812 192.445 45.8388 192.33 45.8772 192.215C47.5197 192.561 49.1168 193.096 50.6369 193.808V193.808Z" fill="black" />
                            <path opacity="0.2" d="M34.9187 263.465C34.9084 263.53 34.8924 263.594 34.8708 263.656C33.8322 263.362 32.7782 263.125 31.7136 262.946C30.409 262.708 29.0923 262.541 27.7695 262.447C27.7695 262.303 27.8367 262.15 27.8751 262.006C29.1888 262.106 30.4939 262.299 31.7808 262.582C32.8442 262.81 33.8923 263.105 34.9187 263.465V263.465Z" fill="black" />
                        </g>
                        <path d="M97.5254 386.165C100.303 385.786 103.116 385.747 105.903 386.05C109.041 386.337 112.105 387.175 114.952 388.526C115.787 388.929 116.516 387.691 115.682 387.288C109.895 384.606 103.434 383.73 97.1416 384.774C96.2299 384.918 96.6234 386.309 97.5254 386.165V386.165Z" fill="#141416" />
                        <path d="M94.7725 386.482C97.7983 386.777 100.71 387.79 103.265 389.438C105.985 391.051 108.37 393.172 110.29 395.685C110.817 396.434 112.065 395.685 111.527 394.956C109.488 392.235 106.923 389.95 103.985 388.238C101.212 386.462 98.0521 385.379 94.7725 385.081C93.8128 385.033 93.8128 386.473 94.7725 386.521V386.482Z" fill="#141416" />
                        <path d="M158.366 370.809C161.14 370.429 163.95 370.391 166.734 370.694C169.875 370.987 172.94 371.824 175.793 373.17C176.618 373.573 177.347 372.335 176.513 371.932C170.725 369.264 164.268 368.398 157.982 369.446C157.071 369.6 157.454 370.982 158.366 370.838V370.809Z" fill="#141416" />
                        <path d="M155.563 371.119C158.591 371.413 161.504 372.43 164.056 374.084C166.778 375.691 169.163 377.809 171.081 380.322C171.608 381.07 172.856 380.322 172.319 379.593C170.277 376.874 167.712 374.59 164.776 372.875C162.001 371.103 158.842 370.02 155.563 369.718C154.604 369.67 154.604 371.109 155.563 371.157V371.119Z" fill="#141416" />
                        <path d="M119.357 193.192C119.357 193.192 96.9399 192.77 80.799 179.575C67.4507 168.645 62.5662 163.262 59.6297 165.949C56.6933 168.636 61.002 212.682 96.0955 222.567C108.043 225.916 119.357 204.334 119.357 204.334V193.192Z" fill="#3772FF" />
                        <path opacity="0.1" d="M119.357 193.192C119.357 193.192 96.9399 192.77 80.799 179.575C67.4507 168.645 62.5662 163.262 59.6297 165.949C56.6933 168.636 61.002 212.682 96.0955 222.567C108.043 225.916 119.357 204.334 119.357 204.334V193.192Z" fill="#3772FF" />
                        <path opacity="0.1" d="M119.359 193.194V204.306C119.359 204.306 108.083 225.907 96.0974 222.539C87.4019 220.113 79.6188 215.168 73.7285 208.327C82.1156 202.847 94.7539 194.633 101.855 190.219C107.55 191.906 113.426 192.905 119.359 193.194Z" fill="black" />
                        <path d="M94.0124 269.225C94.0124 269.225 80.3281 243.488 81.2686 230.917C82.209 218.346 95.6054 197.119 106.737 191.784C117.869 186.448 169.756 194.068 186.894 211.744C190.608 215.582 187.077 222.348 185.167 225.812C182.691 230.293 174.035 240.389 166.531 243.316C166.531 243.316 167.875 258.103 152.137 266.126C152.137 266.126 157.482 291.268 162.357 304.943C167.232 318.617 179.016 341.408 171.78 357.914C164.545 374.419 146.341 373.162 134.355 374.89C122.369 376.617 102.323 388.248 84.5697 385.733C66.8166 383.219 59.7634 363.509 64.763 342.023C70.2713 318.464 79.5508 289.819 94.0124 269.225Z" fill="#3772FF" />
                        <path d="M97.6318 214.575C97.6318 214.575 90.9145 213.942 77.9307 208.702C64.947 203.463 43.4802 189.663 22.6084 200.737C-1.49741 213.52 -9.54867 271.471 36.4366 289.387C76.1074 304.837 104.34 246.204 104.34 246.204L97.6318 214.575Z" fill="#3772FF" />
                        <path opacity="0.1" d="M104.33 246.223C104.33 246.223 76.1074 304.837 36.4365 289.407C26.0725 285.376 18.4531 279.302 13.1464 272.133C8.30169 263.879 5.37922 254.639 4.59614 245.101C3.79965 233.729 5.55577 217.272 20.3052 208.165C35.0546 199.058 53.94 207.675 68.7375 215.669C83.5349 223.663 96.7201 224.306 100.069 225.975C107.343 233.125 104.33 246.223 104.33 246.223Z" fill="black" />
                        <path d="M68.7383 204.586L75.8779 207.388C78.2674 208.3 80.676 209.24 83.0367 210.113C85.3974 210.987 87.8348 211.85 90.2531 212.628C91.4622 213.04 92.6809 213.376 93.9188 213.712C94.533 213.866 95.1472 214.038 95.7709 214.182C96.3947 214.326 97.0184 214.432 97.6902 214.576C95.1196 214.437 92.5715 214.022 90.0899 213.338C87.5986 212.7 85.1446 211.925 82.7392 211.015C80.3305 210.113 77.9411 209.096 75.6284 208.06C73.3157 207.023 70.9358 205.862 68.7383 204.586Z" fill="#141416" />
                        <path d="M90.8105 267.219L92.5379 264.599L94.2844 262.008L97.7486 256.817C98.9002 255.08 100.013 253.333 101.136 251.577C101.683 250.694 102.249 249.821 102.758 248.909C103.266 247.998 103.804 247.115 104.36 246.213C103.641 248.192 102.778 250.117 101.779 251.971C100.819 253.823 99.6871 255.608 98.5451 257.364C97.4032 259.12 96.1749 260.818 94.8794 262.459C93.6062 264.115 92.2481 265.704 90.8105 267.219V267.219Z" fill="#141416" />
                        <path d="M91.8145 213.431C92.1578 213.2 92.5372 213.028 92.9372 212.922C94.124 212.564 95.394 212.591 96.5646 212.999C96.152 213.047 95.7585 213.047 95.3651 213.086L94.1847 213.172C93.3978 213.191 92.6205 213.287 91.8145 213.431Z" fill="#141416" />
                        <path d="M101.422 251.818C101.966 251.163 102.556 250.547 103.188 249.975C103.802 249.399 104.387 248.872 105.02 248.238C104.841 249.12 104.367 249.916 103.677 250.493C103.043 251.111 102.27 251.565 101.422 251.818Z" fill="#141416" />
                        <path d="M101.423 234.948L94.4941 234.698C92.1814 234.622 89.8687 234.545 87.5656 234.516C82.9498 234.449 78.3244 234.372 73.7374 234.718C69.1504 235.063 64.8321 236.685 60.6193 238.489C58.5081 239.391 56.4353 240.408 54.4009 241.492C52.3665 242.577 50.3321 243.69 48.3457 244.87C52.1214 242.175 56.1255 239.815 60.3122 237.817C64.4962 235.702 69.0163 234.332 73.6702 233.768C78.3052 233.523 82.9498 233.523 87.5848 233.768C92.2102 234.017 96.8259 234.382 101.423 234.948Z" fill="#141416" />
                        <path d="M97.6316 236.627C94.8103 237.174 91.9986 237.731 89.2061 238.354C86.4136 238.978 83.6403 239.669 80.9342 240.542C79.5867 240.965 78.2691 241.478 76.9901 242.078C75.7202 242.692 74.4981 243.401 73.3339 244.198C72.1603 245.001 71.0502 245.893 70.0136 246.866C68.972 247.83 68.0093 248.877 67.1348 249.995C68.6462 247.529 70.5979 245.363 72.8925 243.603C74.051 242.713 75.2875 241.93 76.5871 241.262C77.8974 240.625 79.2505 240.079 80.6367 239.631C83.3944 238.767 86.21 238.1 89.0622 237.635C91.8972 237.136 94.7583 236.799 97.6316 236.627Z" fill="#141416" />
                        <path d="M174.498 249.468C174.285 245.26 175.492 241.104 177.924 237.664C178.48 236.916 177.223 236.196 176.686 236.935C175.348 238.79 174.372 240.88 173.807 243.096C173.247 245.171 172.995 247.318 173.059 249.468C173.116 250.427 174.556 250.427 174.498 249.468Z" fill="#141416" />
                        <path d="M175.668 250.474C176.16 248.477 177.15 246.638 178.547 245.128C180.141 243.283 182.102 241.789 184.305 240.743C185.14 240.359 184.411 239.121 183.576 239.495C182.89 239.827 182.229 240.209 181.599 240.637C180.054 241.65 178.653 242.867 177.434 244.255C175.918 245.908 174.839 247.913 174.296 250.09C174.123 251.001 175.505 251.385 175.678 250.474H175.668Z" fill="#141416" />
                        <path d="M176.215 251.711C177.086 250.18 178.318 248.886 179.804 247.94C181.662 246.609 183.865 245.846 186.147 245.742C186.338 245.742 186.521 245.666 186.656 245.531C186.791 245.396 186.867 245.213 186.867 245.022C186.867 244.832 186.791 244.649 186.656 244.514C186.521 244.379 186.338 244.303 186.147 244.303C183.607 244.4 181.15 245.234 179.075 246.702C177.379 247.774 175.969 249.243 174.968 250.982C174.555 251.807 175.793 252.536 176.215 251.711V251.711Z" fill="#141416" />
                        <path d="M132.006 273.391C132.006 273.391 156.487 277.45 172.762 248.978C172.762 248.978 176.284 246.963 177.138 250.801C177.138 250.801 178.99 278.889 162.388 291.537C145.537 304.367 125.846 300.414 125.846 300.414C125.846 300.414 113.371 283.39 132.006 273.391Z" fill="#3772FF" />
                        <path d="M152.685 269.225C150.523 270.906 148.032 272.116 145.373 272.775C144.053 273.12 142.709 273.36 141.352 273.495C139.995 273.62 138.63 273.62 137.273 273.495L141.237 272.727C142.542 272.468 143.837 272.151 145.075 271.816C146.313 271.48 147.628 271.086 148.914 270.664C150.2 270.242 151.447 269.791 152.685 269.225Z" fill="#141416" />
                        <path d="M125.846 300.414C127.437 300.622 129.039 300.741 130.644 300.769C132.256 300.769 133.849 300.769 135.442 300.682C138.632 300.488 141.798 300.013 144.904 299.262C148.013 298.537 151.044 297.508 153.953 296.191C156.881 294.873 159.696 293.316 162.369 291.537C161.736 292.036 161.102 292.497 160.45 293.025C159.837 293.533 159.196 294.007 158.531 294.445L156.458 295.721C155.776 296.162 155.018 296.479 154.308 296.863C152.859 297.594 151.365 298.235 149.836 298.782C149.078 299.07 148.291 299.272 147.514 299.521L146.353 299.886L145.163 300.164C142.001 300.933 138.763 301.342 135.509 301.383C132.259 301.486 129.01 301.16 125.846 300.414V300.414Z" fill="#141416" />
                        <path d="M128.234 301.9C128.697 301.888 129.159 301.846 129.616 301.775C130.077 301.708 130.576 301.66 130.969 301.583C131.871 301.458 132.735 301.305 133.685 301.084C132.986 301.762 132.081 302.187 131.113 302.293C130.632 302.351 130.146 302.351 129.664 302.293C129.162 302.284 128.67 302.149 128.234 301.9V301.9Z" fill="#141416" />
                        <path d="M146.734 227.127C146.571 227.021 146.59 227.559 146.734 227.693C150.448 231.013 154.047 235.428 151.638 239.736C151.616 239.768 151.608 239.807 151.616 239.845C151.623 239.883 151.645 239.916 151.676 239.938C151.708 239.96 151.747 239.968 151.785 239.96C151.823 239.953 151.856 239.931 151.878 239.9C155.707 235.504 150.87 229.689 146.734 227.127Z" fill="#141416" />
                        <path d="M140.211 227.289C133.542 232.874 143.618 243.919 149.807 238.737C155.997 233.555 145.806 222.606 140.211 227.289Z" fill="#141416" />
                        <path d="M125.73 233.028C124.865 231.812 124.273 230.424 123.993 228.959C123.679 227.471 123.653 225.937 123.916 224.439C124.187 222.915 124.822 221.48 125.768 220.255C126.683 219.051 127.907 218.116 129.309 217.549C128.445 218.648 127.684 219.823 127.035 221.061C126.442 222.237 126.028 223.495 125.806 224.794C125.576 226.115 125.466 227.455 125.48 228.796C125.499 230.168 125.586 231.559 125.73 233.028Z" fill="#141416" />
                        <path d="M125.009 233.124C123.915 232.109 123.052 230.872 122.475 229.496C121.859 228.107 121.517 226.611 121.468 225.092C121.418 223.542 121.747 222.004 122.427 220.61C123.066 219.246 124.06 218.079 125.306 217.232C124.688 218.474 124.189 219.772 123.819 221.109C123.484 222.383 123.339 223.699 123.387 225.015C123.43 226.356 123.6 227.689 123.896 228.997C124.193 230.37 124.577 231.713 125.009 233.124Z" fill="#141416" />
                        <path opacity="0.5" d="M163.176 368.287C154.539 373.939 142.899 373.661 134.387 374.889C125.875 376.118 113.458 382.259 100.579 384.879C100.387 374.506 101.347 352.252 111.135 331.14C123.821 303.791 141.843 295.356 147.707 317.178C152.226 334.048 152.735 349.191 163.176 368.287Z" fill="white" />
                        <path d="M152.156 266.128C149.477 267.325 146.633 268.114 143.721 268.469C140.794 268.853 137.827 268.815 134.911 268.354C133.452 268.13 132.01 267.809 130.593 267.395C129.192 266.934 127.829 266.366 126.515 265.696C125.856 265.383 125.215 265.034 124.595 264.65L122.753 263.441C122.144 263.03 121.567 262.575 121.026 262.078C120.469 261.608 119.922 261.119 119.375 260.648C121.697 262.374 124.201 263.841 126.841 265.024C128.154 265.61 129.503 266.11 130.881 266.521C132.258 266.913 133.659 267.214 135.074 267.423C137.914 267.871 140.797 267.984 143.663 267.759L145.813 267.529C146.523 267.414 147.243 267.318 147.953 267.174C149.374 266.914 150.778 266.565 152.156 266.128Z" fill="#141416" />
                        <path d="M188.412 217.577C188.412 217.577 178.912 218.182 178.912 215.312C178.912 213.988 186.167 209.555 188.787 209.555C191.406 209.555 188.412 217.577 188.412 217.577Z" fill="#141416" />
                        <path d="M144.115 254.964C147.91 254.964 150.986 251.888 150.986 248.094C150.986 244.299 147.91 241.223 144.115 241.223C140.32 241.223 137.244 244.299 137.244 248.094C137.244 251.888 140.32 254.964 144.115 254.964Z" fill="#EF466F" />
                        <path d="M203.625 373.236C203.625 371.893 206.12 370.204 206.811 369.782C207.502 369.359 210.649 369.148 210.861 368.611C211.072 368.074 214.104 367.44 215.217 367.498C216.33 367.555 217.827 369.513 218.039 371.058C218.143 371.806 217.974 372.567 217.563 373.201C217.153 373.836 216.528 374.301 215.803 374.513C214.421 374.781 211.897 376.058 210.841 376.163C209.786 376.269 203.625 374.733 203.625 373.236Z" fill="#3772FF" />
                        <path opacity="0.3" d="M203.625 373.236C203.625 371.893 206.12 370.204 206.811 369.782C207.502 369.359 210.649 369.148 210.861 368.611C211.072 368.074 214.104 367.44 215.217 367.498C216.33 367.555 217.827 369.513 218.039 371.058C218.143 371.806 217.974 372.567 217.563 373.201C217.153 373.836 216.528 374.301 215.803 374.513C214.421 374.781 211.897 376.058 210.841 376.163C209.786 376.269 203.625 374.733 203.625 373.236Z" fill="white" />
                        <path d="M192.836 368.64C192.145 367.681 193.709 366.942 194.486 365.819C195.264 364.696 197.874 365.147 198.373 365.502C199.024 366.412 199.317 367.529 199.198 368.64C198.466 369.682 197.358 370.397 196.108 370.636C195.235 370.636 193.268 369.216 192.836 368.64Z" fill="#3772FF" />
                        <path opacity="0.3" d="M192.836 368.64C192.145 367.681 193.709 366.942 194.486 365.819C195.264 364.696 197.874 365.147 198.373 365.502C199.024 366.412 199.317 367.529 199.198 368.64C198.466 369.682 197.358 370.397 196.108 370.636C195.235 370.636 193.268 369.216 192.836 368.64Z" fill="white" />
                        <path d="M205.101 366.778C205.283 365.252 208.258 365.694 208.728 366.519C209.198 367.344 207.481 368.956 206.703 368.285C205.926 367.613 205.005 367.344 205.101 366.778Z" fill="#3772FF" />
                        <path opacity="0.3" d="M205.101 366.778C205.283 365.252 208.258 365.694 208.728 366.519C209.198 367.344 207.481 368.956 206.703 368.285C205.926 367.613 205.005 367.344 205.101 366.778Z" fill="white" />
                        <path d="M194.449 375.712C194.929 375.117 196.368 374.983 196.589 375.328C196.81 375.674 197.28 376.364 196.935 376.643C196.589 376.921 195.62 377.545 195.179 377.199C194.737 376.854 194.219 376.029 194.449 375.712Z" fill="#3772FF" />
                        <path opacity="0.3" d="M194.449 375.712C194.929 375.117 196.368 374.983 196.589 375.328C196.81 375.674 197.28 376.364 196.935 376.643C196.589 376.921 195.62 377.545 195.179 377.199C194.737 376.854 194.219 376.029 194.449 375.712Z" fill="white" />
                        <path d="M184.71 371.029C184.988 370.07 186.37 369.436 187.253 369.331C188.136 369.225 189.777 369.954 189.681 370.578C189.585 371.202 188.337 371.749 188.126 372.2C187.915 372.651 187.262 373.62 186.61 373.361C185.957 373.102 184.393 372.094 184.71 371.029Z" fill="#3772FF" />
                        <path opacity="0.3" d="M184.71 371.029C184.988 370.07 186.37 369.436 187.253 369.331C188.136 369.225 189.777 369.954 189.681 370.578C189.585 371.202 188.337 371.749 188.126 372.2C187.915 372.651 187.262 373.62 186.61 373.361C185.957 373.102 184.393 372.094 184.71 371.029Z" fill="white" />
                    </svg>
                }
            </>
        )
    }
}
